<div *ngIf="false" class="middle-box text-center loginscreen animated fadeInDown">
    <div>
        <div>
            <h1 class="logo-name">FAC</h1>
        </div>
        <h3>Register to Frontline Agent Console</h3>
        <p>Create account to see it in action.</p>
        <form class="m-t" (ngSubmit)="Register()" role="form">
            <h3>Company</h3>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.title" name="title" type="text" class="form-control" placeholder="Company Name" required="" />
            </div>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.email" name="email" type="email" class="form-control" placeholder="Company Email" required="" />
            </div>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.phone" name="phone" type="text" class="form-control" placeholder="Company Phone" />
            </div>
            <div class="form-group">
                <input
                    [(ngModel)]="registrationModel.inContactBusinessUnit"
                    name="phone"
                    type="text"
                    class="form-control"
                    placeholder="inContact Business Unit #"
                    required
                />
            </div>

            <h3>Admin Info</h3>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.firstName" name="firstName" type="firstName" class="form-control" placeholder="First Name" required="" />
            </div>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.lastName" name="lastName" type="lastName" class="form-control" placeholder="Last Name" required="" />
            </div>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.adminEmail" name="adminEmail" type="email" class="form-control" placeholder="Admin Email" required="" />
            </div>
            <div class="form-group">
                <input [(ngModel)]="registrationModel.password" name="password" type="password" class="form-control" placeholder="Admin Password" required="" />
            </div>
            <div class="form-group">
                <div class="checkbox i-checks">
                    <label>
                        <input required title="Read term and condidtions and check this checkbox if you are agreed." type="checkbox" /><i></i> Agree the terms
                        and policy
                    </label>
                </div>
            </div>
            <button type="submit" class="btn btn-primary block full-width m-b">Register</button>

            <p class="text-muted text-center"><small>Already have an account?</small></p>
            <a class="btn btn-sm btn-white btn-block" [routerLink]="['/authentication/login-tep-one']">Login</a>
        </form>
        <p class="m-t"><small>Frontline Services Inc. &copy; 2018</small></p>
    </div>
</div>

<div class="loginColumns animated fadeInDown">
    <app-show-messages #showMessages></app-show-messages>
    <div class="row">
        <div class="col-md-4">
            <h2 class="font-bold">Frontline Agent Console</h2>

            <p>Signup to Frontline Agent Console.</p>

            <p *ngIf="false">
                <small>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</small>
            </p>
        </div>
        <div class="col-md-8 signup-form">
            <div class="ibox-content">
                <form class="m-t" role="form" (ngSubmit)="Register()" *ngIf="!accountCreated">
                    <h4>Company Information</h4>
                    <div>
                        <div class="form-group">
                            <input [(ngModel)]="registrationModel.title" name="title" type="text" class="form-control" placeholder="Company Name" required />
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <input
                                [(ngModel)]="registrationModel.email"
                                name="email"
                                type="email"
                                class="form-control"
                                placeholder="Company Email"
                                required=""
                            />
                        </div>
                        <div class="form-group">
                            <input [(ngModel)]="registrationModel.phone" name="phone" type="text" class="form-control" placeholder="Company Phone" />
                        </div>
                    </div>
                    <h4>Nice Incontact Account Information</h4>
                    <div style="">
                        <div class="form-group">
                            <input
                                type="email"
                                [(ngModel)]="registrationModel.icUsername"
                                name="username"
                                class="form-control"
                                placeholder="Username"
                                required=""
                            />
                        </div>
                        <div class="form-group" style="flex: 0.7">
                            <input
                                type="password"
                                [(ngModel)]="registrationModel.icPassword"
                                name="password"
                                class="form-control"
                                class="form-control"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>
                    <ng-container *ngIf="false">
                        <div style="">
                            <div class="form-group">
                                <input
                                    type="text"
                                    [(ngModel)]="registrationModel.icVendorName"
                                    name="vendorName"
                                    class="form-control"
                                    placeholder="Vendor Name"
                                />
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    [(ngModel)]="registrationModel.icApplicationName"
                                    name="applicationName"
                                    class="form-control"
                                    class="form-control"
                                    placeholder="Application Name"
                                />
                            </div>
                        </div>
                        <div style="">
                            <div class="form-group">
                                <input type="text" [(ngModel)]="registrationModel.icApiSecret" name="apiSecret" class="form-control" placeholder="Api Secret" />
                            </div>
                        </div>
                    </ng-container>
                    <div style="">
                        <div class="form-group">
                            <label style="font-weight: normal">
                                <input
                                    required
                                    [(ngModel)]="registrationModel.agreeToTerms"
                                    name="agreeToTerms"
                                    title="Read term and condidtions and check this checkbox if you are agreed."
                                    type="checkbox"
                                /><i></i> Agree the terms and policy
                            </label>
                        </div>
                        <div class="form-group">
                            <button
                                type="submit"
                                class="btn btn-primary block full-width m-b"
                                [disabled]="!registrationModel.agreeToTerms || !registrationModel.icUsername || !registrationModel.icPassword"
                            >
                                Create Account
                            </button>
                        </div>
                    </div>

                    <a *ngIf="false" [routerLink]="['/account/forgotpassword']">
                        <small>Forgot password?</small>
                    </a>
                </form>

                <div class="text-center info-box" *ngIf="accountCreated">
                    <i class="fas fa-check-circle text-success" style="font-size: 50px"></i>
                    <h2 class="font-bold">Success</h2>

                    <p>
                        Your account has been registered successfully but it's currently inactive. Kindly contact us at
                        <a target="_blank" href="mailto:support@frontline.group">support@frontline.group</a> to complete your registration process.
                        <br /><br />Thanks!
                    </p>
                    <p class="text-left">
                        You can read admin and agent manuals here:
                        <br />
                        <a href="https://frontline.group/frontline-agent-console-agent-user-manual/">Read Agent Manual</a>
                        <br />
                        <a href="https://frontline.group/frontline-agent-console-admin-user-guide/">Read Admin Manual</a>
                    </p>
                </div>

                <div>
                    <p class="text-muted text-center"><small>Already have an account?</small></p>
                    <a class="btn btn-sm btn-white btn-block" [routerLink]="['/authentication/login-tep-one']">Login</a>
                </div>
            </div>
        </div>
    </div>

    <hr />
    <div class="row">
        <div class="col-md-6">Copyright Frontline Services Inc.</div>
        <div class="col-md-6 text-right">
            <small> &copy; {{ year - 1 }}-{{ year }}</small>
        </div>
    </div>
</div>
