import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';
import {Company} from '../../models/Company';
import {SocketConnection} from '../../../sockets/socket.connections';
import {Agent} from '../../models/Agent';
import {CrmIntegrationApplication} from '../../models/CrmIntegrationApplication';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `Company` model.
 */
@Injectable()
export class CompanyApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * Find a related item by id for agents.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for agents
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public findByIdAgents(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Delete a related item by id for agents.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for agents
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public destroyByIdAgents(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Update a related item by id for agents.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for agents
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public updateByIdAgents(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Find a related item by id for crmApplicatoins.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for crmApplicatoins
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public findByIdCrmApplicatoins(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Delete a related item by id for crmApplicatoins.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for crmApplicatoins
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public destroyByIdCrmApplicatoins(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Update a related item by id for crmApplicatoins.
     *
     * @param {any} id Company id
     *
     * @param {any} fk Foreign key for crmApplicatoins
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public updateByIdCrmApplicatoins(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Queries agents of Company.
     *
     * @param {any} id Company id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getAgents(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in agents of this model.
     *
     * @param {any} id Company id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public createAgents(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Deletes all agents of this model.
     *
     * @param {any} id Company id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public deleteAgents(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Counts agents of Company.
     *
     * @param {any} id Company id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    public countAgents(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents/count';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Queries crmApplicatoins of Company.
     *
     * @param {any} id Company id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getCrmApplicatoins(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in crmApplicatoins of this model.
     *
     * @param {any} id Company id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public createCrmApplicatoins(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Deletes all crmApplicatoins of this model.
     *
     * @param {any} id Company id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public deleteCrmApplicatoins(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Counts crmApplicatoins of Company.
     *
     * @param {any} id Company id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    public countCrmApplicatoins(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins/count';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @param {string} sectionid
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getSettingsSection(id: any, sectionid: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/settings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/settings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} sectionid
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setSettings(id: any, sectionid: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/settings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public createAccount(data: any, customHeaders?: Function): Observable<Agent> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/create-account';
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setIncontactSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/incontactsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setIncontactApiSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/incontactapisettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getIncontactSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/incontactsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setDynamicsSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/dynamicssettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getDynamicsSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/dynamicssettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @param {string} sectionid
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getMappingsSection(id: any, sectionid: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/mappings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getMappings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/mappings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} sectionid
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setMappings(id: any, sectionid: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/mappings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in agents of this model.
     *
     * @param {any} id Company id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public createManyAgents(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/agents';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in crmApplicatoins of this model.
     *
     * @param {any} id Company id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public createManyCrmApplicatoins(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Companies/:id/crmApplicatoins';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Company`.
     */
    public getModelName() {
        return 'Company';
    }
}
