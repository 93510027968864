import {Injectable} from '@angular/core';

import {Observable, throwError} from 'rxjs';

import {AuthenticationContextModel} from '.././models/authentication-context.model';
import {LocalStorageSettingsService} from './local-storage-settings.service';
import {AuthenticationContextService} from './authentication-context.service';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ResourceOwnerPasswordAuthorizerService {
    private userName: string;
    private password: string;
    private authContext: AuthenticationContextModel;

    private firstSessionId: string;
    private sessionId: string;

    constructor(
        private http: HttpClient,
        private authContextService: AuthenticationContextService,
        private localStorageSettingsService: LocalStorageSettingsService
    ) {
        this.authContext = authContextService.getAuthContext();

        // console.log(this.authContext);
    }

    /*     refreshToken(refreshTokeUrl, refreshToken: string): Observable<AuthenticationContextModel | any> {

            let token64 = btoa(InContactApiSettings.clientId + ':' + InContactApiSettings.clientSecret); //base64 encoding
            //let headers = new Headers({ 'Authorization': 'Basic '  + token64, 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' });
            let headers = new Headers({ 'Authorization': 'Basic ' + token64, 'Content-Type': 'application/json' });

            let options = new RequestOptions({ headers: headers });
            let parameters = { 'grant_type': 'refresh_token', 'refresh_token': refreshToken };

            return this.http.post(refreshTokeUrl, parameters, options)
                .map(this.OnSuccess)
                .catch((err) => this.onSendRequestRawError(err));
        } */

    // OnSuccess(res: HttpResponse<any>) {
    //     return res.body; //JSON.stringify(res.json());
    // }

    OnError(error: HttpResponse<any> | any) {
        let errMsg: string;
        if (error instanceof HttpResponse) {
            const body = error.body || '';
            const err = body.error || JSON.stringify(body);

            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log(errMsg);
        return throwError(error);
    }

    sendHttpPost(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.localStorageSettingsService.getSettings();
        /*let fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        let headers = new Headers({ 'Authorization': 'Bearer ' + this.authContext.access_token, 'Content-Type': 'application/json' });

        let options = new RequestOptions({ headers: headers });

        return this.http.post(fullUri, params, options)
            .map(this.OnSuccess)
            .catch((err)=>this.onSendRequestRawError(err));
        */
        return this.sendHttpPostWithToken(relativeUrl, params, this.authContext.access_token, this.authContext.resource_server_base_uri, 'POST');
    }

    sendHttpPostWithToken(relativeUrl: string, params: JSON, access_token: string, serverUri: string, method: string): Observable<any> {
        this.authContext = this.localStorageSettingsService.getSettings();
        let fullUri = serverUri + relativeUrl;
        let headers = new HttpHeaders({Authorization: 'Bearer ' + access_token, 'Content-Type': 'application/json'});

        if (method == 'PUT') {
            return this.http
                .put(fullUri, params, {
                    headers: headers
                })
                .pipe(
                    map(res => res),
                    catchError(err => this.onSendRequestRawError(err))
                );
        } else {
            return this.http
                .post(fullUri, params, {
                    headers
                })
                .pipe(
                    map(res => res),
                    catchError(err => this.onSendRequestRawError(err))
                );
        }
    }

    sendHttpGet(relativeUrl: string): Observable<any> {
        this.authContext = this.localStorageSettingsService.getSettings();
        let fullUri = '';

        if (this.authContext) {
            fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        }

        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.authContext.access_token,
            'Content-Type': 'application/json'
        });

        return this.http
            .get(fullUri, {
                headers
            })
            .pipe(
                map(res => res),
                catchError(err => this.onSendRequestRawError(err))
            );
    }

    sendHttpPut(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.localStorageSettingsService.getSettings();
        let fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.authContext.access_token,
            'Content-Type': 'application/json'
        });

        return this.http.put(fullUri, params, {headers}).pipe(
            map(res => res),
            catchError(err => this.onSendRequestRawError(err))
        );
    }

    sendHttpDelete(relativeUrl: string, params: JSON): Observable<any> {
        this.authContext = this.localStorageSettingsService.getSettings();

        let fullUri = this.authContext.resource_server_base_uri + relativeUrl;
        let headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.authContext.access_token,
            'Content-Type': 'application/json'
        });

        return this.http
            .delete(fullUri, {
                headers
            })
            .pipe(
                map(res => res),
                catchError(err => this.onSendRequestRawError(err))
            );
    }

    onSendRequestRawError(error: Response | any) {
        var self = this;
        let errMsg: string;
        if (error instanceof Response) {
            console.log(error);

            if (error.status == 401 && error.statusText == 'Unauthorized') {
                console.log('Session End Error:');
            } else {
                errMsg = `${error.status} - ${error.statusText || ''} ${error}`;

                if (error.status !== 304) console.log(errMsg);
            }
            //Return the Error
            return throwError(error);
        }
        return null;
    }
}
