/* tslint:disable */

declare var Object: any;
export interface ReportInterface {
    id?: number;
}

export class Report implements ReportInterface {
    'id': number;
    constructor(data?: ReportInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `Report`.
     */
    public static getModelName() {
        return 'Report';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Report for dynamic purposes.
     **/
    public static factory(data: ReportInterface): Report {
        return new Report(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'Report',
            plural: 'Reports',
            path: 'Reports',
            idName: 'id',
            properties: {
                id: {
                    name: 'id',
                    type: 'number'
                }
            },
            relations: {}
        };
    }
}
