import {Location} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
//import { obser } from '@angular/obser';
import {Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute} from '@angular/router';
import {NewCompanyModel} from 'src/sdk';
import {AuthencationApi, NewCompaniesApi} from 'src/sdk/api-services';
import {ApplicationAuthService} from 'src/sdk/core';
import {OldUacUserApi} from '../../../sdk';
import {AuthenticationContextService} from '../../../services/inContact/authentication-context.service';
import {SpinnerService} from '../spinner/spinner.service';

declare var jQuery: any;

@Component({
    selector: 'navigation',
    templateUrl: 'navigation.template.html'
})
export class NavigationComponent implements OnInit {
    /*
    getCurrentUser() {
        if(!this.userService)
        return {};
        this.user = this.userService.getCachedCurrent();
        if (!this.user)
            return {};

            console.log(this.user);
    }
    */
    private CurrentUser = null;

    public companiesList: Array<NewCompanyModel> = [];

    constructor(
        private router: Router,
        private location: Location,
        public userService: OldUacUserApi,
        private authenticationContextSrv: AuthenticationContextService,
        public authenticationApi: AuthencationApi,
        @Inject(SpinnerService) public spinnerSrv: SpinnerService,
        @Inject(ApplicationAuthService) public applicationAuth: ApplicationAuthService,
        @Inject(NewCompaniesApi) public newCompaniesApi: NewCompaniesApi
    ) {
        //userService.getCurrent()
        //this.user = userSerive.getCurrent();
        //this.user.firstName
        router.events.subscribe((event: RouterEvent) => {
            //this.navigationInterceptor(event);
        });
    }

    public ngOnInit(): void {
        //
    }

    ngAfterViewInit() {
        jQuery('#side-menu').metisMenu();
    }

    public get isMyCompany() {
        return this.applicationAuth.getCurrentCompanyId() === this.applicationAuth.getCurrentUserCompanyId();
    }

    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
    }
    logout() {
        let me = this;
        this.spinnerSrv.ShowSpinner();
        // this.userService.logout().subscribe(result => {
        //     this.authenticationContextSrv.clearAuthContext();
        //     this.spinner.HideSpinner();
        //     me.router.navigate(['/authentication/login-tep-one']);
        // });

        this.authenticationApi
            .Logout()
            .subscribe(res => {})
            .add(() => {
                this.authenticationContextSrv.clearAuthContext();
                this.spinnerSrv.HideSpinner();
                me.router.navigate(['/authentication/login-step-one']);
            });
    }

    /*
    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (this.userSerive.isAuthenticated()) {
            //var str = localStorage.getItem('_user');
            this.CurrentUser = this.userSerive.getCachedCurrent();
        }
    }
    */
}
