import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';

import {Report} from '../../models/Report';
import {SocketConnection} from '../../../sockets/socket.connections';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `Report` model.
 */
@Injectable()
export class ReportApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {number} year
     *
     * @param {number} month
     *
     * @param {number} companyId
     *
     * @param {number} timezoneDifferenceHours
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Report` object.)
     * </em>
     */
    public dashboard(
        year: any,
        month: any,
        companyId: any = {},
        timezoneDifferenceHours: any = {},
        customHeaders?: Function
    ): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Reports/dashboard';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof year !== 'undefined' && year !== null) _urlParams.year = year;
        if (typeof month !== 'undefined' && month !== null) _urlParams.month = month;
        if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
        if (typeof timezoneDifferenceHours !== 'undefined' && timezoneDifferenceHours !== null)
            _urlParams.timezoneDifferenceHours = timezoneDifferenceHours;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {number} year
     *
     * @param {number} month
     *
     * @param {number} companyId
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Report` object.)
     * </em>
     */
    public detailedAgentLoginReport(
        year: any,
        month: any,
        companyId: any = {},
        customHeaders?: Function
    ): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Reports/detailedAgentLoginReport';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof year !== 'undefined' && year !== null) _urlParams.year = year;
        if (typeof month !== 'undefined' && month !== null) _urlParams.month = month;
        if (typeof companyId !== 'undefined' && companyId !== null) _urlParams.companyId = companyId;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Report`.
     */
    public getModelName() {
        return 'Report';
    }
}
