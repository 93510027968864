import {NgModule} from '@angular/core';
import {mainViewComponent} from './main-view.component';
// import {ChartModule} from 'angular2-chartjs';
// import {DataTableModule} from 'angular2-serverpagination-datatable';
import {CommonModule} from '@angular/common';
import {ChartJsModule} from 'src/app/plugins/chart.js/chartjs.module';

@NgModule({
    declarations: [mainViewComponent],
    imports: [
        CommonModule,
        ChartJsModule
        // ChartModule, DataTableModule
    ],
    exports: [ChartJsModule]
})
export class MainViewModule {}
