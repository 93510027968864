import {Component, HostBinding} from '@angular/core';
import {Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {ApplicationAuthService} from 'src/sdk/core';
import {correctHeight, detectBody} from './app.helpers';
import {OldUacUserApi} from './sdk';
import {LoopBackAuth} from './sdk/core';
import {AuthenticationContextService} from './services/inContact/authentication-context.service';

// // Core vendor styles
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../../node_modules/font-awesome/css/font-awesome.css';
// import '../../node_modules/animate.css/animate.min.css';

// // Main Inspinia CSS files
// import '../../src/assets/styles/style.css';
import {InContactAPIService} from './services/inContact/incontact-api.service';
import {SpinnerService} from './views/common/spinner/spinner.service';

declare var jQuery: any;

@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    @HostBinding('class') class = 'app-component';
    // Sets initial value to true to show loading spinner on first load
    loading: boolean = true;
    private FullPagesList = [
        '/authentication',
        '/register',
        '/authentication/login-step-one',
        '/authentication/login-step-two',
        '/account/login',
        '/account/registration',
        '/account/forgotpassword'
    ];
    private PublicPages = [
        '/authentication',
        '/register',
        '/authentication/login-step-one',
        '/authentication/login-step-two',
        '/account/login',
        '/account/registration',
        '/account/forgotpassword'
    ];
    public router: Router = null;
    public isFullPage(): boolean {
        let currentRoute = this.router.createUrlTree([this.router.url]).toString();
        let retVal: boolean = this.FullPagesList.indexOf(currentRoute) > -1;
        //console.log(currentRoute);
        return retVal;
    }
    constructor(
        private userService: OldUacUserApi,
        private _router: Router,
        public spinnerSrv: SpinnerService,
        private inContactAPIService: InContactAPIService,
        private applicationAuth: ApplicationAuthService,
        private authContextSrv: AuthenticationContextService
    ) {
        this.userService = userService;
        this.router = _router;
        //console.log(userService);
        _router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
    }

    ngAfterViewInit() {
        // Run correctHeight function on load and resize window event
        jQuery(window).bind('load resize', function () {
            correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        jQuery('.metismenu a').click(() => {
            setTimeout(() => {
                correctHeight();
            }, 300);
        });
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            if (
                this.PublicPages.indexOf((event as any).url) == -1 &&
                // !this.userService.isAuthenticated()
                !this.applicationAuth.isAuthenticated()
            ) {
                // console.log((event as any).url);
                this.applicationAuth.clear();
                this.authContextSrv.clearAuthContext();
                this.router.navigate(['/authentication/login-step-one']);
                return;
            }
        }
        if (this.spinnerSrv) {
            if (event instanceof NavigationStart) {
                //this.spinner.ShowSpinner();
            }
            // if (event instanceof NavigationEnd) {
            //     this.spinner.HideSpinner();
            // }

            // Set loading state to false in both of the below events to hide the spinner in case a request fails
            if (event instanceof NavigationCancel) {
                // this.spinnerSrv.HideSpinner();
            }
            if (event instanceof NavigationError) {
                // this.spinnerSrv.HideSpinner();
            }

            // console.log(this.spinnerSrv.SpinnerVisible);
        }
    }
}
