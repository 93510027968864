<div class="loginColumns animated fadeInDown gray-bg">
    <app-show-messages #showMessages></app-show-messages>
    <div class="row">
        <div class="col-md-6">
            <h2 class="font-bold">Welcome to Frontline Agent Console Administration Panel</h2>

            <p>Manage agents. Create, Activate or Deactivate agent accounts.</p>

            <p>Manage your integration settings universally for your account.</p>

            <p>View Reporting. Check agent activity and session reports</p>

            <p *ngIf="false">
                <small>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</small>
            </p>
        </div>
        <div class="col-md-6">
            <div class="ibox-content">
                <router-outlet></router-outlet>
                <p class="text-muted text-center">
                    <small>Do not have an account?</small>
                </p>
                <a class="btn btn-sm btn-white btn-block" [routerLink]="['/register']">Create an account</a>
                <!-- <p class="m-t">
                    <small>Frontline Agent Console Administration &copy; {{ year }}</small>
                </p> -->
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-6">Copyright Frontline Services Inc.</div>
        <div class="col-md-6 text-right">
            <small> &copy; {{ year - 1 }}-{{ year }}</small>
        </div>
    </div>
</div>
