<app-admin-form #adminForm> </app-admin-form>
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="ibox-content">
            <button class="btn btn-primary" (click)="saveAdmin()" type="button">Save Admin</button>

            <a *ngIf="dataModel.active" (click)="updateAdminStatus(false)" class="btn btn-white btn-xs pull-right"
                >Deactivate Account</a
            >
            <a *ngIf="!dataModel.active" (click)="updateAdminStatus(true)" class="btn btn-white btn-xs pull-right"
                >Activate</a
            >
            <a *ngIf="dataModel.active" (click)="setPasswordModal(template)" class="btn btn-white btn-xs pull-right"
                >Set Password</a
            >
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fa fa-users"></i> Administrator</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-set-password #setPasswordForm> </app-set-password>
    </div>
    <div>
        <button class="btn btn-primary" (click)="setPassword()" type="button">Set Password</button>
    </div>
</ng-template>
