import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {BarChartComponent} from 'src/app/plugins/chart.js/bar-chart/bar-chart.component';
import {NewAgentsApi, NewReportsApi} from 'src/sdk/api-services';
import {ApplicationAuthService} from 'src/sdk/core';
import {ReportApi, AgentApi} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'mianView',
    templateUrl: 'main-view.template.html'
})
export class mainViewComponent implements OnInit, AfterViewInit {
    @ViewChild('agentActivityChart', {static: false})
    public agentActivityChart: BarChartComponent;

    @ViewChild('agentActivityChart1', {static: false})
    public agentActivityChart1: BarChartComponent;

    companyId: number | null = null;

    constructor(
        public spinner: SpinnerService,
        private reportApi: ReportApi,
        @Inject(NewReportsApi) private newReportApi: NewReportsApi,
        @Inject(NewAgentsApi) private newAgentsApi: NewAgentsApi,
        private agentApi: AgentApi,
        @Inject(ApplicationAuthService) protected applicationAuth: ApplicationAuthService
    ) {
        console.log('It works here');
    }

    type = 'bar';
    type1 = 'pie';
    data: ChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            // {
            //     label: 'My First dataset',
            //     backgroundColor: '#1ab394',
            //     data: [65, 59, 80, 81, 56, 55, 40]
            // }
        ]
    };
    data1 = {
        datasets: [
            {
                data: [10, 20],
                backgroundColor: ['#1ab394']
            }
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: ['Active', 'In-Active']
    };
    options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                display: true,
                ticks: {
                    stepSize: 1,
                    autoSkip: false,
                    display: true,
                    includeBounds: true
                },
                suggestedMax: 1,
                suggestedMin: 0,
                beginAtZero: true
            }
        }
    };

    rows: any[] = [];
    currentMonth = 1;
    currentYear = 2018;
    years: number[] = [];
    months: number[] = [];
    now = new Date();
    totalActiveAgents = 0;

    public showChart: boolean = false;
    public showDetail: boolean = false;
    public showPieChart: boolean = false;

    ngOnInit() {
        let me = this;
        this.companyId = this.applicationAuth.getCurrentCompanyId();

        //var now = new Date();
        this.currentYear = this.now.getFullYear();
        this.currentMonth = this.now.getMonth() + 1;

        for (var d = this.currentYear - 5; d <= this.currentYear; d++) {
            this.years.push(d);
        }

        for (var d = 1; d <= 12; d++) {
            this.months.push(d);
        }
        setTimeout(() => {
            this.loadData();
        }, 1000);
    }

    ngAfterViewInit() {}

    currentYearData(year: number) {
        this.currentYear = year;
        this.loadData();
    }

    loadPreviousMonth() {
        let previousMonthDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1, 0, 0, 0);
        previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
        this.currentYear = previousMonthDate.getFullYear();
        this.currentMonth = previousMonthDate.getMonth() + 1;
        this.loadData();
    }

    loadThisMonth() {
        let previousMonthDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1, 0, 0, 0);
        //previousMonthDate.setMonth(previousMonthDate.getMonth());
        this.currentYear = previousMonthDate.getFullYear();
        this.currentMonth = previousMonthDate.getMonth() + 1;
        this.loadData();
    }

    loadData() {
        this.showChart = false;
        this.showDetail = false;
        this.showPieChart = false;

        let labels = [];
        let localData = [];
        var firstOfMonth = new Date(this.now.getFullYear(), this.now.getMonth(), 1, 0, 0, 0);
        var timezoneOffset = (this.now.getTimezoneOffset() / 60) * -1;
        this.spinner.ShowSpinner();

        this.newReportApi
            //.find({ "include": [{ "relation": "sessions", "scope": { "fields": ["id", "date"], "where": { "date": { "gt": new Date(now.getFullYear(), now.getMonth(), 1) } } } }], "fields": ["id"], "where": { "companyId": 1 } })
            //.dashboard(firstOfMonth.toISOString(), null, timezoneOffset)
            .dashboardReport(this.companyId!, this.currentYear, this.currentMonth, timezoneOffset)

            .subscribe((r: any[]) => {
                for (
                    var d = new Date(this.currentYear, this.currentMonth - 1, 1);
                    d <= new Date(this.currentYear, this.currentMonth, 0);
                    d.setDate(d.getDate() + 1)
                ) {
                    labels.push(new Date(d).toLocaleDateString());
                    r.filter(y => {
                        var recDate = new Date(y.Date).toLocaleDateString();
                        var compDate = d.toLocaleDateString();
                        return recDate == compDate;
                    }).map(x => {
                        var val = x.Agents;
                        localData.push(parseInt(val.toString()));
                        return val;
                    });
                }
                this.data.labels = labels;
                this.data.datasets[0] = {label: 'Loggedin Agents', data: localData, backgroundColor: '#1ab394'};
                this.showChart = true;

                this.agentActivityChart.renderChart();
            })
            .add(() => {
                this.spinner.HideSpinner();
            });

        // this.spinner.ShowSpinner();
        this.newReportApi
            .detailedAgentLoginReport(this.companyId, this.currentYear, this.currentMonth)
            .subscribe(r => {
                this.rows = r;
                this.totalActiveAgents = r.length;
                this.showDetail = true;

                this.newAgentsApi.count(this.companyId).subscribe(countResult => {
                    this.data1.datasets[0] = {
                        data: [r.length, countResult.count - r.length],
                        backgroundColor: ['#1ab394']
                    };
                    this.showPieChart = true;

                    // this.agentActivityChart1.renderChart(this.type1);
                });
            })
            .add(() => {
                this.spinner.HideSpinner();
            });
    }
}
