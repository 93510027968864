import {Inject, Injectable} from '@angular/core';
import {ApplicationAuthService, BaseLoopBackApi, JSONSearchParams} from '../core';
import {HttpClient} from '@angular/common/http';
import {FrontlineSDKModels} from '../services';
import {AnyObject} from 'src/globals';
import {ApiConfig} from '../lb.config';
import {EmailTemplateModel} from '../models';

@Injectable()
export class EmailTemplatesApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public create(data: EmailTemplateModel) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/email-templates';
        let _routeParams: any = {};
        let _postBody: any = {data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public count(where: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates/count`;
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (where) _urlParams.where = where;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getTemplates(filter: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates`;
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getTemplateById(id: number, filter: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates/:id`;
        let _routeParams: any = {id};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public isTypeExist(type: string) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates/:type/exist`;
        let _routeParams: any = {type};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public makeActive(id: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates/:id/make-active`;
        let _routeParams: any = {id};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public makeInActive(id: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/email-templates/:id/make-inactive`;
        let _routeParams: any = {id};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public updateTemplateById(id: number, data: AnyObject) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/email-templates/:id';
        let _routeParams: any = {id};
        let _postBody: any = {agent: data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }
}
