import {HttpClient} from '@angular/common/http';
import {Injectable, Inject} from '@angular/core';
import {BaseLoopBackApi, ApplicationAuthService, JSONSearchParams} from '../core';
import {FrontlineSDKModels} from '../services';
import {ApiConfig} from '../lb.config';
import {CrmTemplateModel} from '../models';
import {AnyObject} from 'src/globals';
import {Observable} from 'rxjs';

@Injectable()
export class CrmTemplatesApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public create(data: CrmTemplateModel) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates';
        let _routeParams: any = {};
        let _postBody: any = {data};
        let _urlParams: any = {};

        let result = this.POST_Request<CrmTemplateModel>(_url, _routeParams, _urlParams, _postBody);

        return result;
    }

    public makeActive(id: number) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/crm-templates/:id/make-active`;
        let _routeParams: any = {id};
        let _urlParams: any = {};

        let result = this.GET_Request<any>(_url, _routeParams, _urlParams);

        return result;
    }

    public makeInActive(id: number) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/crm-templates/:id/make-inactive`;
        let _routeParams: any = {id};
        let _urlParams: any = {};

        let result = this.GET_Request<any>(_url, _routeParams, _urlParams);

        return result;
    }

    public count(where: AnyObject = {}) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/crm-templates/count`;
        let _routeParams: any = {};
        let _urlParams: any = {};
        if (where) _urlParams.where = where;

        let result = this.GET_Request<any>(_url, _routeParams, _urlParams);

        return result;
    }

    public getCrmTemplates(filter: AnyObject = {}) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/crm-templates`;
        let _routeParams: any = {};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.GET_Request<Array<CrmTemplateModel>>(_url, _routeParams, _urlParams);

        return result;
    }

    public getTemplateById(id: number, filter: AnyObject = {}) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/crm-templates/:id`;
        let _routeParams: any = {id};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.GET_Request<CrmTemplateModel>(_url, _routeParams, _urlParams);

        return result;
    }

    public updateTemplateById(id: number, data: AnyObject) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:id';
        let _routeParams: any = {id};
        let _postBody: any = {agent: data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getAgentLoginTypeSettings(templateId: number): Observable<any> {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/agentLoginTypeSettings';
        let _routeParams: any = {
            templateId
        };

        let _urlParams: any = {};

        let result = this.GET_Request<any>(_url, _routeParams, _urlParams);
        return result;
    }

    public setAgentLoginTypeSettings(templateId: number, data: any): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/agentLoginTypeSettings';
        let _routeParams: any = {
            templateId
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public getOtherSettings(templateId: number): Observable<any> {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/otherSettings';
        let _routeParams: any = {
            templateId
        };
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public setOtherSettings(templateId: number, data: any): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/otherSettings';
        let _routeParams: any = {
            templateId
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public getContactSettingsSection(templateId: number, sectionid: any = {}): Observable<any> {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/contactsettings/:sectionid';
        let _routeParams: any = {
            templateId,
            sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public setContactSettingsSection(templateId: number, sectionid: any, data: any): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/contactsettings/:sectionid';
        let _routeParams: any = {
            templateId,
            sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public getMappingsSection(templateId: number, sectionid: string): Observable<any> {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/mappings/:sectionid';
        let _routeParams: any = {
            templateId,
            sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public setMappingsSection(templateId: number, sectionid: string, data: any): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/crm-templates/:templateId/mappings/:sectionid';
        let _routeParams: any = {
            templateId,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }
}
