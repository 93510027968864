import {Component, OnInit} from '@angular/core';
import {OldUacUserApi} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html'
    //styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {
    constructor(private userService: OldUacUserApi, private spinner: SpinnerService) {}
    model = {
        email: null
    };
    passwordResetSuccess = false;
    ngOnInit() {
        this.passwordResetSuccess = false;
    }

    forgotPassword() {
        let me = this;
        me.spinner.ShowSpinner();
        me.userService
            .resetPassword(me.model)

            .subscribe(result => {
                me.passwordResetSuccess = true;
                console.log(result);
            })
            .add(() => {
                me.spinner.HideSpinner();
            });
    }

    ngAfterViewInit() {
        this.spinner.HideSpinner();
    }
}
