import {Inject, Injectable} from '@angular/core';
import {ApplicationAuthService, BaseLoopBackApi, JSONSearchParams} from '../core';
import {HttpClient} from '@angular/common/http';
import {FrontlineSDKModels} from '../services';
import {SendEmailNotificationViewModel} from '../view-models';
import {ApiConfig} from '../lb.config';
import {AnyObject} from 'src/globals';
import {EmailNotificationHistoryModel} from '../models';

@Injectable()
export class EmailNotificationHistoryApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public count(where?: AnyObject) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/email-notification-history/count';
        let _routeParams: any = {};

        let _urlParams: any = {};
        if (where) _urlParams.where = where;

        let result = this.GET_Request<any>(_url, _routeParams, _urlParams);

        return result;
    }

    public getHistories(filter?: AnyObject) {
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/email-notification-history';
        let _routeParams: any = {};

        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.GET_Request<Array<EmailNotificationHistoryModel>>(_url, _routeParams, _urlParams);

        return result;
    }
}
