import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {AgentApi} from '../../sdk';
import {SpinnerService} from '../../views/common/spinner/spinner.service';
import {AdminFormComponent} from './admin-form/admin-form.component';

@Component({
    selector: 'app-admins',
    templateUrl: './admins.component.html'
    //styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {
    constructor(private adminApi: AgentApi, private spinner: SpinnerService, private router: Router) {}
    activeFilter(val) {
        this._activeFilter = val;
        console.log(this._activeFilter);
        //this.loadData();
    }
    _activeFilter = true;
    filter: any = {};
    // @ViewChild('modal')
    // modal: ModalComponent;

    @ViewChild('adminForm')
    AdminFormComponent: AdminFormComponent;

    items: string[] = ['item1', 'item2', 'item3'];

    index: number = 0;
    backdropOptions = [true, false, 'static'];
    cssClass: string = '';
    selected: string;

    animation: boolean = true;
    keyboard: boolean = true;
    backdrop: string | boolean = true;
    css: boolean = false;

    open() {
        // this.modal.open();
    }

    public rows: Array<any> = [];
    public columns: Array<any> = [
        {title: 'First Name', name: 'firstName' /*, filtering: {filterString: '', placeholder: 'Filter by name'}*/},

        {title: 'Last Name', className: ['office-header', 'text-success'], name: 'lastName' /*sort: 'asc'*/},
        {title: 'Email', name: 'email', sort: '' /*, filtering: {filterString: '', placeholder: 'Filter by extn.'}*/}
    ];

    searchQuery = null;

    viewmodel: any = {
        activeFilter: 'active',
        searchKeywords: null,
        sortBy: 'FirstName',
        sortOrder: 'asc',
        itemsTotal: 0,
        activePage: 1,
        rowsOnPage: 25
    };

    public config: any = {
        paging: true,
        sorting: {columns: this.columns},
        filtering: {filterString: ''},
        className: ['table-striped', 'table-bordered']
    };

    private data: Array<any> = [];

    public loadData() {
        let me = this;
        var filter = JSON.parse(JSON.stringify(this.filter));
        filter.where = {};

        /*
    if (this.searchQuery) {
      filter.where.email = { like: '%' + this.searchQuery + '%' };
      filter.where.firstName = { like: '%' + this.searchQuery + '%' };
      filter.where.lastName = { like: '%' + this.searchQuery + '%' };
    }
    */

        if (this.searchQuery) {
            // this.filter.email = { like: '%' + this.searchQuery + '%' };
            // this.filter.firstName = { like: '%' + this.searchQuery + '%' };
            // this.filter.lastName = { like: '%' + this.searchQuery + '%' };

            filter.where = {
                active: this._activeFilter,
                isAdmin: true,
                or: [
                    {email: {like: '%' + this.searchQuery + '%'}},
                    {firstName: {like: '%' + this.searchQuery + '%'}},
                    {lastName: {like: '%' + this.searchQuery + '%'}}
                ]
            };
        } else {
            filter.where = {};
            filter.where.active = this._activeFilter;
        }

        filter.where.isAdmin = true;
        filter.where.active = this._activeFilter;

        filter.limit = this.viewmodel.rowsOnPage;
        filter.skip = (this.viewmodel.activePage - 1) * this.viewmodel.rowsOnPage;
        filter.order = this.viewmodel.sortBy + ' ' + this.viewmodel.sortOrder;

        me.spinner.ShowSpinner();
        this.adminApi.count(filter.where).subscribe(countResult => {
            this.viewmodel.itemsTotal = countResult.count;
            this.adminApi
                .find(filter)

                .subscribe(
                    result => {
                        this.rows = result;
                    },
                    err => {
                        console.log(err);
                    }
                )
                .add(() => {
                    me.spinner.HideSpinner();
                });
        });
    }
    ngAfterViewInit() {
        this.loadData();
    }
    ngOnInit() {}

    onPageChange(event) {
        this.viewmodel.rowsOnPage = event.rowsOnPage;
        this.viewmodel.activePage = event.activePage;
        this.loadData();
    }

    onSortOrder(event) {
        this.loadData();
    }
}
