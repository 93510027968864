import {Agent} from '../../index';

declare var Object: any;
export interface AgentSessionInterface {
    agentId: number;
    date: Date;
    id?: number;
    company?: Agent;
}

export class AgentSession implements AgentSessionInterface {
    'agentId': number;
    'date': Date;
    'id': number;
    company: Agent;
    constructor(data?: AgentSessionInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `AgentSession`.
     */
    public static getModelName() {
        return 'AgentSession';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of AgentSession for dynamic purposes.
     **/
    public static factory(data: AgentSessionInterface): AgentSession {
        return new AgentSession(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'AgentSession',
            plural: 'AgentSessions',
            path: 'AgentSessions',
            idName: 'id',
            properties: {
                agentId: {
                    name: 'agentId',
                    type: 'number'
                },
                date: {
                    name: 'date',
                    type: 'Date',
                    default: new Date(0)
                },
                id: {
                    name: 'id',
                    type: 'number'
                }
            },
            relations: {
                company: {
                    name: 'company',
                    type: 'Agent',
                    model: 'Agent',
                    relationType: 'belongsTo',
                    keyFrom: 'agentId',
                    keyTo: 'id'
                }
            }
        };
    }
}
