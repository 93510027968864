import {Injectable} from '@angular/core';

@Injectable()
export class SpinnerService {
    constructor() {}
    public SpinnerVisible: boolean = false;
    public ShowSpinner() {
        this.SpinnerVisible = true;
        // console.log('showing spinner');
    }

    public HideSpinner() {
        this.SpinnerVisible = false;
        // console.log('hiding spinner');
    }
}
