import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {parseJwt} from '../../../../../globals';
import {AuthenticationContextModel} from '../../../../services/models/authentication-context.model';
import {BaseLoopBackApi, JSONSearchParams, LoopBackAuth} from '../../../core';
import {ErrorHandler} from '../../../core/error.service';
import {LoopBackConfig} from '../../../lb.config';
import {SDKModels} from '../../../old/services';
import {SocketConnection} from '../../../sockets/socket.connections';
import {AccessTokenPayloadModel, IdTokenPayloadModel} from '../../models';

@Injectable()
export class UacUserApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    loginStepOne(data: {email: string}) {
        const url = LoopBackConfig.getApiBasePath + '/UacUsers/login-step-one';
        return this.POST_Request(url, undefined, undefined, {data});
    }

    loginWithCode(code: string, email: string) {
        const url = LoopBackConfig.getApiBasePath + '/UacUsers/login-with-code';

        return this.POST_Request(url, undefined, undefined, {email, code}).pipe(
            map((res: any) => {
                const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.ic_token_data.access_token);
                const idTokenPayload = parseJwt<IdTokenPayloadModel>(res.ic_token_data.id_token);
                const authContext = new AuthenticationContextModel({
                    access_token: res.ic_token_data.access_token,
                    id_token: res.ic_token_data.id_token,
                    refresh_token: res.ic_token_data.refresh_token,
                    agent_id: `${accessTokenPayload.icAgentId}`,
                    scope: accessTokenPayload.icScope,
                    refresh_token_server_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/InContactAuthorizationServer/Token`,
                    resource_server_base_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/inContactAPI/`,
                    expires_in: res.ic_token_data.expires_in,
                    token_type: res.ic_token_data.token_type
                });

                res.ic_token_data = authContext;

                this.auth.setToken(res);

                return res;
            }),
            catchError((err, cauth) => {
                return throwError(err);
            })
        );
    }

    getModelName(): string {
        return 'UacUser';
    }
}
