import {Injectable, Inject, Optional} from '@angular/core';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {JSONSearchParams} from './search.params';
import {ErrorHandler} from './error.service';
import {ApplicationAuthService} from './application-auth.service';
import {ApiConfig} from '../lb.config';
import {LoopBackFilter, AccessToken} from '../models/BaseModels';
import {FrontlineSDKModels} from '../services/custom/frontline-sdk-models';

import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {map, filter as rxFilter, delay, catchError} from 'rxjs/operators';
// Making Sure EventSource Type is available to avoid compilation issues.
declare var EventSource: any;

@Injectable()
export abstract class BaseLoopBackApi {
    protected path: string;
    protected model: any;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        // this.model = this.models.get(this.getModelName());
    }

    public handleError(error: HttpResponse<any>) {
        console.log(error);
        return throwError(error.body || (error as any)?.error?.error || (error as any)?.error || 'Server error');
    }

    public GET_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, pubsub: boolean = false, customHeaders?: Function) {
        return this.request<T>('GET', url, routeParams, urlParams, {}, pubsub, customHeaders);
    }

    public POST_Request<T>(url: string, routeParams: any = {}, urlParams: any = {}, postBody: any = {}, pubsub: boolean = false, customHeaders?: Function) {
        return this.request<T>('POST', url, routeParams, urlParams, postBody, pubsub, customHeaders);
    }

    public request<T>(
        method: string,
        url: string,
        routeParams: any = {},
        urlParams: any = {},
        postBody: any = {},
        pubsub: boolean = false,
        customHeaders?: Function
    ): Observable<T> {
        // Transpile route variables to the actual request Values
        Object.keys(routeParams).forEach((key: string) => {
            url = url.replace(new RegExp(':' + key + '(/|$)', 'g'), routeParams[key] + '$1');
        });
        if (pubsub) {
            if (url.match(/fk/)) {
                let arr = url.split('/');
                arr.pop();
                url = arr.join('/');
            }
            let event: string = `[${method}]${url}`.replace(/\?/, '');
            let subject: Subject<any> = new Subject<any>();
            // this.connection.on(event, (res: any) => subject.next(res));
            return subject.asObservable();
        } else {
            // Headers to be sent
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            // Authenticate request
            headers = this.authenticate(url, headers);
            // Body fix for built in remote methods using "data", "options" or "credentials
            // that are the actual body, Custom remote method properties are different and need
            // to be wrapped into a body object
            let body: any;
            let postBodyKeys = typeof postBody === 'object' ? Object.keys(postBody) : [];
            if (postBodyKeys.length === 1) {
                body = postBody[postBodyKeys.shift()];
            } else {
                body = postBody;
            }
            let filter: string = '';
            // Separate filter object from url params and add to search query
            if (urlParams.filter) {
                if (ApiConfig.isHeadersFilteringSet()) {
                    headers = headers.append('filter', JSON.stringify(urlParams.filter));
                } else {
                    filter = `?filter=${encodeURIComponent(JSON.stringify(urlParams.filter))}`;
                }
                delete urlParams.filter;
            }
            // Separate where object from url params and add to search query
            /**
                 CODE BELOW WILL GENERATE THE FOLLOWING ISSUES:
                - https://github.com/mean-expert-official/loopback-sdk-builder/issues/356
                - https://github.com/mean-expert-official/loopback-sdk-builder/issues/328
                if (urlParams.where) {
                    headers.append('where', JSON.stringify(urlParams.where));
                    delete urlParams.where;
                }
                **/

            let httpParams = new HttpParams();

            //   this.searchParams.setJSON(urlParams);

            Object.keys(urlParams).forEach(paramKey => {
                let paramValue = urlParams[paramKey];
                paramValue = typeof paramValue === 'object' ? JSON.stringify(paramValue) : paramValue;
                httpParams = httpParams.append(paramKey, paramValue);
            });

            if (typeof customHeaders === 'function') {
                headers = customHeaders(headers);
            }

            let request = new HttpRequest(method as any, `${url}${filter}`, body, {
                headers,
                params: httpParams,
                withCredentials: ApiConfig.getRequestOptionsCredentials()
            });
            return this.http.request(request).pipe(
                rxFilter(event => event instanceof HttpResponse),
                delay(0),
                map((res: any) => {
                    return res.body;
                }),
                catchError((e, caught) => {
                    console.log(e);
                    return this.handleError(e);
                })
            );
        }
    }

    public authenticate<T>(url: string, headers: HttpHeaders) {
        if (this.auth.getAccessTokenId()) {
            headers = headers.append('Authorization', ApiConfig.getAuthPrefix() + this.auth.getAccessTokenId());
        }
        return headers;
    }

    // public create<T>(data: T, customHeaders?: Function): Observable<T> {
    //     return this.request(
    //         'POST',
    //         [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
    //         undefined,
    //         undefined,
    //         {data},
    //         null,
    //         customHeaders
    //     ).pipe(map((data: T) => this.model.factory(data)));
    // }

    public onCreate<T>(data: T[]): Observable<T[]> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            true
        ).pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
    }

    public createMany<T>(data: T[], customHeaders?: Function): Observable<T[]> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            null,
            customHeaders
        ).pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
    }

    public onCreateMany<T>(data: T[]): Observable<T[]> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            true
        ).pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
    }

    // public findById<T>(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    //     let _urlParams: any = {};
    //     if (filter) _urlParams.filter = filter;
    //     return this.request(
    //         'GET',
    //         [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id'].join('/'),
    //         {id},
    //         _urlParams,
    //         undefined,
    //         null,
    //         customHeaders
    //     ).pipe(map((data: T) => this.model.factory(data)));
    // }

    // public find<T>(filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
    //     return this.request(
    //         'GET',
    //         [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
    //         undefined,
    //         {filter},
    //         undefined,
    //         null,
    //         customHeaders
    //     ).pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
    // }

    public exists<T>(id: any, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'GET',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id/exists'].join('/'),
            {id},
            undefined,
            undefined,
            null,
            customHeaders
        );
    }

    public findOne<T>(filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'GET',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'findOne'].join('/'),
            undefined,
            {filter},
            undefined,
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public updateAll<T>(where: any = {}, data: T, customHeaders?: Function): Observable<{count: 'number'}> {
        let _urlParams: any = {};
        if (where) _urlParams.where = where;
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'update'].join('/'),
            undefined,
            _urlParams,
            {data},
            null,
            customHeaders
        );
    }

    public onUpdateAll<T>(where: any = {}, data: T): Observable<{count: 'number'}> {
        let _urlParams: any = {};
        if (where) _urlParams.where = where;
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'update'].join('/'),
            undefined,
            _urlParams,
            {data},
            true
        );
    }

    public deleteById<T>(id: any, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'DELETE',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id'].join('/'),
            {id},
            undefined,
            undefined,
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onDeleteById<T>(id: any): Observable<T> {
        return this.request<any>(
            'DELETE',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id'].join('/'),
            {id},
            undefined,
            undefined,
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public count(where: any = {}, customHeaders?: Function): Observable<{count: number}> {
        let _urlParams: any = {};
        if (where) _urlParams.where = where;
        return this.request<any>(
            'GET',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'count'].join('/'),
            undefined,
            _urlParams,
            undefined,
            null,
            customHeaders
        );
    }

    // public updateAttributes<T>(id: any, data: T, customHeaders?: Function): Observable<T> {
    //     return this.request(
    //         'PUT',
    //         [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id'].join('/'),
    //         {id},
    //         undefined,
    //         {data},
    //         null,
    //         customHeaders
    //     ).pipe(map((data: T) => this.model.factory(data)));
    // }

    public onUpdateAttributes<T>(id: any, data: T): Observable<T> {
        return this.request<any>(
            'PUT',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id'].join('/'),
            {id},
            undefined,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public upsert<T>(data: any = {}, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'PUT',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onUpsert<T>(data: any = {}): Observable<T> {
        return this.request<any>(
            'PUT',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public upsertPatch<T>(data: any = {}, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'PATCH',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onUpsertPatch<T>(data: any = {}): Observable<T> {
        return this.request<any>(
            'PATCH',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path].join('/'),
            undefined,
            undefined,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public upsertWithWhere<T>(where: any = {}, data: any = {}, customHeaders?: Function): Observable<T> {
        let _urlParams: any = {};
        if (where) _urlParams.where = where;
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'upsertWithWhere'].join('/'),
            undefined,
            _urlParams,
            {data},
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onUpsertWithWhere<T>(where: any = {}, data: any = {}): Observable<T> {
        let _urlParams: any = {};
        if (where) _urlParams.where = where;
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'upsertWithWhere'].join('/'),
            undefined,
            _urlParams,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public replaceOrCreate<T>(data: any = {}, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'replaceOrCreate'].join('/'),
            undefined,
            undefined,
            {data},
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onReplaceOrCreate<T>(data: any = {}): Observable<T> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'replaceOrCreate'].join('/'),
            undefined,
            undefined,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public replaceById<T>(id: any, data: any = {}, customHeaders?: Function): Observable<T> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id', 'replace'].join('/'),
            {id},
            undefined,
            {data},
            null,
            customHeaders
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public onReplaceById<T>(id: any, data: any = {}): Observable<T> {
        return this.request<any>(
            'POST',
            [ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, ':id', 'replace'].join('/'),
            {id},
            undefined,
            {data},
            true
        ).pipe(map((data: T) => this.model.factory(data)));
    }

    public createChangeStream(): Observable<any> {
        let subject = new Subject();
        if (typeof EventSource !== 'undefined') {
            let emit = (msg: any) => subject.next(JSON.parse(msg.data));
            var source = new EventSource([ApiConfig.getPath(), ApiConfig.getApiVersion(), this.model.getModelDefinition().path, 'change-stream'].join('/'));
            source.addEventListener('data', emit);
            source.onerror = emit;
        } else {
            console.warn('SDK Builder: EventSource is not supported');
        }
        return subject.asObservable();
    }

    abstract getModelName(): string;
}
