import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {AuthenticationContextModel} from '../models/authentication-context.model';

import {LocalStorageSettingsService} from './local-storage-settings.service';
import {ResourceOwnerPasswordAuthorizerService} from './resource-owner-password-authorizer.service';
import {AuthenticationContextService} from './authentication-context.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class InContactAPIService {
    private authenticationContext: AuthenticationContextModel;

    private refreshTokenTimer: any = null;

    //private _InContactApiSettings:InContactApiSettings;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageSettingsService,
        public authenticationContextService: AuthenticationContextService,
        private passwrodAuthorizer: ResourceOwnerPasswordAuthorizerService
    ) {
        // console.log(this.passwrodAuthorizer);
    }

    clearScheduleRefreshTimer() {
        window.clearTimeout(this.refreshTokenTimer);
    }

    /* scheduleRefreshToken() {
        if (!this.authenticationContextService.authenticationContextModel)
            return;

        let timeout = (this.authenticationContextService.authenticationContextModel.expires_in - 600) * 1000; //10000; //
        let me = this;
        console.log("scheduled timeout for refresh token")
        this.clearScheduleRefreshTimer();
        this.refreshTokenTimer = window.setTimeout(() => {
            me.passwrodAuthorizer.refreshToken(me.authenticationContextService.authenticationContextModel.refresh_token_server_uri, me.authenticationContextService.authenticationContextModel.refresh_token).subscribe(
                (res) => {
                    res => this.authenticationContext = res;
                    //this._localStorageService.set("currentAgent", agent);
                    this.authenticationContextService.saveAuthContext(res);
                    this.authenticationContextService.tryReloadState();
                    //this._inContactSessionService.startSession(res,agent.sessionValue,agent.sessionValue);
                    me.scheduleRefreshToken();
                    return Observable.of(true);

                },
                (error) => {
                    return Observable.of(error);
                });
        }, timeout);

    } */

    OnError(error: any) {
        //if(error instanceof(Inc))
    }

    clearAuthContext() {
        this.authenticationContextService.clearAuthContext();
    }

    getAuthContext() {
        return this.authenticationContextService.getAuthContext();
    }

    tryReloadState(): boolean {
        //return false
        let authContext = this.authenticationContextService.tryReloadState();
        return false;
    }

    //Disposition ------------------------
    getAgentStates(): Observable<any> {
        //let relativeUri = InContactApiSettings.servicesURl+ "agents/states";
        //let relativeUri = InContactApiSettings.servicesURl+ "agents/states?Fields=agentId,firstName,lastName,skillId,teamId,startDate,agentStateId,agentStateName,isACW,outStateId,outStateDescription";
        let relativeUri = '/services/v13.0/agents?fields=agentId%2CfirstName%2ClastName%2CuserName&isActive=true';
        let parameters: any;
        parameters = {};

        return this.passwrodAuthorizer.sendHttpGet(relativeUri).pipe(
            map(
                res => {
                    //agents=res.agentStates;
                    var agents = res.agents;
                    return agents;
                },
                error => {
                    return throwError(error);
                }
            )
        );
    }
}
