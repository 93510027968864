import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, Inject} from '@angular/core';
import {BaseLoopBackApi, ApplicationAuthService, JSONSearchParams} from '../core';
import {FrontlineSDKModels} from '../services';
import {ApiConfig} from '../lb.config';

@Injectable()
export class StorageApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public saveImage(image: File) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getStoragePath() + `/api/images/upload-file`;
        let _routeParams: any = {};

        const formData = new FormData();
        formData.append('image', image);

        let _postBody: any = {formData};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, formData, null, (headers: HttpHeaders) => {
            headers = headers.delete('Content-Type');
            return headers;
            // return headers.append('Content-Type', 'multipart/form-data');
        });

        return result;
    }
}
