export class MyUserProfile {
    id?: number;
    name?: string;
    firstName: string;
    lastName?: string;
    email?: string;
    phone?: string;
    companyId?: number;
    rememberMe: boolean;
    IsEmailVerified?: boolean;

    [attribute: string]: any;

    constructor(data?: Partial<MyUserProfile>) {
        Object.assign(this, data);
    }
}
