export class ApiConfig {
    private static path: string = '//0.0.0.0:3030';
    private static version: string | number = 'api';
    private static authPrefix: string = 'Bearer ';
    private static debug: boolean = true;
    private static filterOn: string = '';
    private static secure: boolean = false;
    private static withCredentials: boolean = false;
    private static storagePath: string = '';

    public static setApiVersion(version: string = 'api'): void {
        ApiConfig.version = version;
    }

    public static getApiVersion(): string | number {
        return ApiConfig.version;
    }

    public static setBaseURL(url: string = '/'): void {
        ApiConfig.path = url;
    }

    public static getPath(): string {
        return ApiConfig.path;
    }

    public static setStorageBaseURL(url: string = '/'): void {
        ApiConfig.storagePath = url;
    }
    public static getStoragePath(): string {
        return ApiConfig.storagePath;
    }

    public static get getApiBasePath() {
        return ApiConfig.getPath() + '/' + ApiConfig.getApiVersion();
    }

    public static setAuthPrefix(authPrefix: string = ''): void {
        ApiConfig.authPrefix = authPrefix;
    }

    public static getAuthPrefix(): string {
        return ApiConfig.authPrefix;
    }

    public static setDebugMode(isEnabled: boolean): void {
        ApiConfig.debug = isEnabled;
    }

    public static debuggable(): boolean {
        return ApiConfig.debug;
    }

    public static filterOnUrl(): void {
        ApiConfig.filterOn = 'url';
    }

    public static filterOnHeaders(): void {
        ApiConfig.filterOn = 'headers';
    }

    public static isHeadersFilteringSet(): boolean {
        return ApiConfig.filterOn === 'headers';
    }

    public static setSecureWebSockets(): void {
        ApiConfig.secure = true;
    }

    public static unsetSecureWebSockets(): void {
        ApiConfig.secure = false;
    }

    public static isSecureWebSocketsSet(): boolean {
        return ApiConfig.secure;
    }

    public static setRequestOptionsCredentials(withCredentials: boolean = false): void {
        ApiConfig.withCredentials = withCredentials;
    }

    public static getRequestOptionsCredentials(): boolean {
        return ApiConfig.withCredentials;
    }
}
