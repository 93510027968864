import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationComponent} from './component/authentication.component';
import {LoginStepOneComponent} from './login-step-one/login-step-one.component';
import {LoginStepTwoComponent} from './login-step-two/login-step-two.component';
import {RegistrationComponent} from './registration/registration.component';

const authenticationRoutes: Routes = [
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            {
                path: '',
                redirectTo: 'login-step-one',
                pathMatch: 'full'
            },

            {
                path: 'login-step-one',
                component: LoginStepOneComponent
            },
            {
                path: 'login-step-two',
                component: LoginStepTwoComponent
            }
        ]
    },
    {
        path: 'register',
        component: RegistrationComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(authenticationRoutes)],
    exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
