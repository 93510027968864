import {Inject, Injectable} from '@angular/core';
import {ApplicationAuthService, BaseLoopBackApi, JSONSearchParams} from '../core';
import {HttpClient} from '@angular/common/http';
import {FrontlineSDKModels} from '../services';
import {SendEmailNotificationViewModel} from '../view-models';
import {ApiConfig} from '../lb.config';

@Injectable()
export class EmailNotificationApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public sendEmailNotification(data: SendEmailNotificationViewModel) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/email-notification/send';
        let _routeParams: any = {};
        let _postBody: any = {data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }
}
