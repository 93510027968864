import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';
import {CrmIntegrationApplication} from '../../models/CrmIntegrationApplication';
import {SocketConnection} from '../../../sockets/socket.connections';
import {Company} from '../../models/Company';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `CrmIntegrationApplication` model.
 */
@Injectable()
export class CrmIntegrationApplicationApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * Fetches belongsTo relation company.
     *
     * @param {any} id CrmIntegrationApplication id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getCompany(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/company';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @param {object} options **Do not implement in clients**
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public getAgentLoginTypeSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/agentLoginTypeSettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     *  - `options` – `{object}` - **Do not implement in clients**
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Company` object.)
     * </em>
     */
    public setAgentLoginTypeSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/agentLoginTypeSettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};

        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    public getOtherSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/otherSettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    public setOtherSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/otherSettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};

        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getCrmAccountSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/crmaccountsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public setCrmAccountSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/crmaccountsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getContactSettings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/contactsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public setContactSettings(id: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/contactsettings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @param {string} sectionid
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getContactSettingsSection(id: any, sectionid: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/contactsettings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} sectionid
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public setContactSettingsSection(id: any, sectionid: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/crm-integration-applications/:id/contactsettings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getMappings(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/mappings';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} sectionid
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public setMappings(id: any, sectionid: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/mappings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @param {string} sectionid
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public getMappingsSection(id: any, sectionid: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/mappings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} sectionid
     *
     * @param {object} data Request data.
     *
     *  - `id` – `{string}` -
     *
     *  - `data` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `CrmIntegrationApplication` object.)
     * </em>
     */
    public setMappingsSection(id: any, sectionid: any, data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/CrmIntegrationApplication/:id/mappings/:sectionid';
        let _routeParams: any = {
            id: id,
            sectionid: sectionid
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `CrmIntegrationApplication`.
     */
    public getModelName() {
        return 'CrmIntegrationApplication';
    }
}
