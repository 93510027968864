/* tslint:disable */
import {Company, AgentSession} from '../../index';

declare var Object: any;
export interface AgentInterface {
    firstName: string;
    lastName: string;
    email: string;
    active?: boolean;
    isAdmin?: boolean;
    dateCreated: Date;
    dateModified: Date;
    applicationType?: string;
    id?: number;
    companyId?: number;
    company?: Company;
    accessTokens?: any[];
    sessions?: AgentSession[];
}

export class Agent implements AgentInterface {
    'firstName': string;
    'lastName': string;
    'email': string;
    'active': boolean;
    'isAdmin': boolean;
    'dateCreated': Date;
    'dateModified': Date;
    'applicationType': string;
    'id': number;
    'companyId': number;
    company: Company;
    accessTokens: any[];
    sessions: AgentSession[];
    constructor(data?: AgentInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `Agent`.
     */
    public static getModelName() {
        return 'Agent';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Agent for dynamic purposes.
     **/
    public static factory(data: AgentInterface): Agent {
        return new Agent(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'Agent',
            plural: 'Agents',
            path: 'Agents',
            idName: 'id',
            properties: {
                firstName: {
                    name: 'firstName',
                    type: 'string'
                },
                lastName: {
                    name: 'lastName',
                    type: 'string'
                },
                email: {
                    name: 'email',
                    type: 'string'
                },
                active: {
                    name: 'active',
                    type: 'boolean',
                    default: true
                },
                isAdmin: {
                    name: 'isAdmin',
                    type: 'boolean',
                    default: false
                },
                dateCreated: {
                    name: 'dateCreated',
                    type: 'Date',
                    default: new Date(0)
                },
                dateModified: {
                    name: 'dateModified',
                    type: 'Date',
                    default: new Date(0)
                },
                applicationType: {
                    name: 'applicationType',
                    type: 'string',
                    default: ''
                },
                id: {
                    name: 'id',
                    type: 'number'
                },
                companyId: {
                    name: 'companyId',
                    type: 'number'
                }
            },
            relations: {
                company: {
                    name: 'company',
                    type: 'Company',
                    model: 'Company',
                    relationType: 'belongsTo',
                    keyFrom: 'companyId',
                    keyTo: 'id'
                },
                accessTokens: {
                    name: 'accessTokens',
                    type: 'any[]',
                    model: '',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'userId'
                },
                sessions: {
                    name: 'sessions',
                    type: 'AgentSession[]',
                    model: 'AgentSession',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'agentId'
                }
            }
        };
    }
}
