

    <form class="form-horizontal form-horizontal" #adminForm="ngForm" id="setpassword-form" (ngSubmit)="setPassword()" role="form">


      <div class="row">
        <div class="col-lg-12">

              <div class="form-group"><label class="col-sm-2 control-label">New Password</label>

                <div class="col-sm-10">
                  <input type="password" name="newPassword" [(ngModel)]="newPassword" required class="form-control" placeholder="New Password (Required)"></div>
              </div>
              <div class="form-group">
                <div class="hr-line-dashed"></div>
                <label class="col-sm-2 control-label">Confirm Password</label>

                <div class="col-sm-10">
                  <input type="password" name="confirmPassword" [(ngModel)]="confirmPassword" required class="form-control" placeholder="Confirm Password (Required)">
                </div>
              </div>

              <span *ngIf="newPassword != confirmPassword">Enter the same password in confirm password field!</span>
            
        </div>
      </div>
    </form>

    <button style="display: none;" type="submit" [disabled]="newPassword != confirmPassword">Set Password</button>
  