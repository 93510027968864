import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {InContactAPIService} from '../../../services/inContact/incontact-api.service';
import {CompanyApi, OldUacUserApi} from '../../../sdk';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';
import {LoopBackAuth} from '../../../sdk/core';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
    @HostBinding('class') class = 'registration-component gray-bg';
    registrationModel: any = {};
    errorMessage: any = {};

    accountCreated: boolean = false;

    constructor(
        private companyService: CompanyApi,
        private spinner: SpinnerService,
        private router: Router,
        private userApi: OldUacUserApi,
        private mssagesService: ShowMessagesService,
        private inContactAPIService: InContactAPIService,
        protected auth: LoopBackAuth
    ) {
        this.companyService = companyService;
        this.spinner = spinner;
    }

    ngOnInit() {}

    get year() {
        return new Date().getFullYear();
    }

    Register() {
        this.registrationModel.username = this.registrationModel.email;
        console.log(this.registrationModel);

        this.spinner.ShowSpinner();
        this.companyService
            .createAccount(this.registrationModel) //.map(response => response)

            .subscribe(
                result => {
                    this.accountCreated = !!result;
                    // this.auth.setToken(result);
                    // this.inContactAPIService.authenticationContextService.saveAuthContext(result.ic_token_data);
                    // this.router.navigate(['/']);
                },
                error => {
                    // this.handleError(error);
                    // this.errorMessage = <any>error;
                    console.log(error);
                    this.mssagesService.message.next({type: 'error', text: error.message});
                }
            )
            .add(() => {
                this.spinner.HideSpinner();
            });
    }

    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof HttpResponse) {
            const body = error.body || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return throwError(errMsg);
    }

    ngAfterViewInit() {
        this.spinner.HideSpinner();
    }
}
