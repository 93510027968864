<form class="m-t" novalidate #stepOneForm="ngForm" (ngSubmit)="Next()">
    <div class="form-group">
        <label for="username">Email <span class="text-danger">*</span></label>
        <input
            id="username"
            type="email"
            [(ngModel)]="authenticationSrv.loginData.username"
            (ngModelChange)="authenticationSrv.loginData.username = $event"
            name="username"
            class="form-control"
            placeholder="Email"
            required
        />
    </div>
    <button type="submit" class="btn btn-primary block full-width m-b" [disabled]="!stepOneForm.form.valid">
        Next
    </button>
</form>
