import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {RouterModule} from '@angular/router';
import {BreadcrumbService} from './services/breadcrumb.service';
import {ShowMessagesModule} from '../views/common/show-messages/show-messages.module';
import {SpinnerModule} from '../views/common/spinner/spinner.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ContactSettingsComponent} from '../modules/crm-integration-applications/contact-settings/contact-settings.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ShowMessagesModule,
        SpinnerModule.forRoot(),
        NgxDatatableModule,
        TabsModule.forRoot(),
        AlertModule.forRoot()
    ],
    declarations: [BreadcrumbComponent, ContactSettingsComponent],
    exports: [
        BreadcrumbComponent,
        ContactSettingsComponent,
        CommonModule,
        FormsModule,
        RouterModule,
        ShowMessagesModule,
        SpinnerModule,
        NgxDatatableModule,
        TabsModule,
        AlertModule
    ],
    providers: [BreadcrumbService]
})
export class SharedModule {
    constructor() {
        //
    }
}
