/* tslint:disable */
declare var Object: any;
import {Injectable, Inject} from '@angular/core';
import {FacInternalStorage} from '../storage/storage.swaps';
import {SDKToken} from '../models/BaseModels';
import {NewCompanyModel} from '../models';
import {MyUserProfile, RoleViewModel} from '../view-models';

@Injectable()
export class ApplicationAuthService {
    private token: SDKToken = new SDKToken();

    protected prefix: string = '$LoopBackSDK$';

    constructor(@Inject(FacInternalStorage) protected storage: FacInternalStorage) {
        this.token.id = this.load('id');
        this.token.token = this.load('token');
        this.token.user = this.load('user');
        this.token.roles = this.load('roles');
        this.token.companyId = this.load('companyId');
        this.token.company = this.load('company');
        this.token.userId = this.load('userId');
        this.token.created = this.load('created');
        this.token.ttl = this.load('ttl');
        this.token.Expires = this.load('Expires') ?? 0;
        this.token.rememberMe = this.load('rememberMe');
    }

    public setRememberMe(value: boolean): void {
        this.token.rememberMe = value;
    }

    public setUser(user: any) {
        this.token.user = user;
        this.save();
    }

    public setCompany(company: NewCompanyModel) {
        this.token.company = company;
        this.save();
    }

    public setToken(token: SDKToken): void {
        this.token = Object.assign({}, this.token, token);
        this.save();
    }

    public getToken(): SDKToken {
        return <SDKToken>this.token;
    }

    public get roles(): Array<RoleViewModel> {
        return this.token.roles ?? [];
    }
    public isUserInRole(slug: string) {
        return this.roles.some(x => x.slug === slug);
    }
    public get isSuperAdmin() {
        return this.isUserInRole('super-admin');
    }

    public getAccessTokenId(): string {
        return this.token.id;
    }

    public getCurrentUserId(): any {
        return this.token.userId;
    }

    public getCurrentUserCompany(): any {
        return this.token.user?.company ?? null;
    }

    public getCurrentUserCompanyId(): any {
        return this.token.user?.company?.id ?? null;
    }

    public getCurrentCompanyId(): number | null {
        return this.token.company?.id ?? null;
    }

    public getCurrentUserData(): MyUserProfile {
        return typeof this.token.user === 'string' ? JSON.parse(this.token.user) : this.token.user;
    }

    public get getCurrentCompany(): any {
        if ((this.token.company as any) === 'undefined') {
            return null;
        }
        return typeof this.token.company === 'string' ? JSON.parse(this.token.company) : this.token.company;
    }

    public save(): boolean {
        let today = new Date();
        let expires = new Date(today.getTime() + this.token.ttl * 1000);
        this.persist('id', this.token.id, expires);
        this.persist('token', this.token.token, expires);
        this.persist('user', this.token.user, expires);
        this.persist('roles', this.token.roles, expires);
        this.persist('company', this.token.company, expires);
        this.persist('companyId', this.token.companyId, expires);
        this.persist('userId', this.token.userId, expires);
        this.persist('created', this.token.created, expires);
        this.persist('ttl', this.token.ttl, expires);
        this.persist('Expires', this.token.Expires ?? 0, expires);
        this.persist('rememberMe', this.token.rememberMe, expires);
        return true;
    }

    protected load(prop: string): any {
        return this.storage.get(`${this.prefix}${prop}`);
    }

    public clear(): void {
        Object.keys(this.token).forEach((prop: string) => this.storage.remove(`${this.prefix}${prop}`));
        this.token = new SDKToken();
    }

    protected persist(prop: string, value: any, expires?: Date): void {
        try {
            console.log(prop, value);
            this.storage.set(`${this.prefix}${prop}`, typeof value === 'object' ? JSON.stringify(value) : value, this.token.rememberMe ? expires : null);
        } catch (err) {
            console.error('Cannot access local/session storage:', err);
        }
    }

    public isAuthenticated() {
        return !!this.token.userId;
    }
}
