import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Component({
    selector: 'app-login-step-two',
    templateUrl: './login-step-two.component.html'
})
export class LoginStepTwoComponent implements OnInit, AfterViewInit {
    constructor(public authenticationSrv: AuthenticationService, public router: Router) {}

    ngOnInit() {
        if (!this.authenticationSrv.publicProfile) {
            this.router.navigate(['authentication', 'login-step-one']);
        }
    }

    ngAfterViewInit() {}

    Login() {
        this.authenticationSrv.LoginWithCredentials();
    }
}
