import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';
import {Agent} from '../../models/Agent';
import {SocketConnection} from '../../../sockets/socket.connections';
import {Company} from '../../models/Company';
import {AgentSession} from '../../models/AgentSession';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `Agent` model.
 */
@Injectable()
export class AgentApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * Fetches belongsTo relation company.
     *
     * @param {any} id Agent id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public getCompany(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/company';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Find a related item by id for accessTokens.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for accessTokens
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public findByIdAccessTokens(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Delete a related item by id for accessTokens.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for accessTokens
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public destroyByIdAccessTokens(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Update a related item by id for accessTokens.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for accessTokens
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public updateByIdAccessTokens(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Find a related item by id for sessions.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for sessions
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public findByIdSessions(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Delete a related item by id for sessions.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for sessions
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public destroyByIdSessions(id: any, fk: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Update a related item by id for sessions.
     *
     * @param {any} id Agent id
     *
     * @param {any} fk Foreign key for sessions
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public updateByIdSessions(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'PUT';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions/:fk';
        let _routeParams: any = {
            id: id,
            fk: fk
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Queries accessTokens of Agent.
     *
     * @param {any} id Agent id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public getAccessTokens(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in accessTokens of this model.
     *
     * @param {any} id Agent id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public createAccessTokens(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Deletes all accessTokens of this model.
     *
     * @param {any} id Agent id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public deleteAccessTokens(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Counts accessTokens of Agent.
     *
     * @param {any} id Agent id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    public countAccessTokens(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens/count';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Queries sessions of Agent.
     *
     * @param {any} id Agent id
     *
     * @param {object} filter
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public getSessions(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in sessions of this model.
     *
     * @param {any} id Agent id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public createSessions(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Deletes all sessions of this model.
     *
     * @param {any} id Agent id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public deleteSessions(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Counts sessions of Agent.
     *
     * @param {any} id Agent id
     *
     * @param {object} where Criteria to match model instances
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `count` – `{number}` -
     */
    public countSessions(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions/count';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} email
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public publicProfile(email: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/publicprofile';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof email !== 'undefined' && email !== null) _urlParams.email = email;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} id
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public getDynamicsToken(id: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/dynamicstoken';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public sendReport(data: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/sendreport';
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public import(data: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/import';
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in accessTokens of this model.
     *
     * @param {any} id Agent id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public createManyAccessTokens(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/accessTokens';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * Creates a new instance in sessions of this model.
     *
     * @param {any} id Agent id
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Agent` object.)
     * </em>
     */
    public createManySessions(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/Agents/:id/sessions';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Agent`.
     */
    public getModelName() {
        return 'Agent';
    }
}
