import {Injectable} from '@angular/core';
import {ComponentFixtureAutoDetect} from '@angular/core/testing';
import {BehaviorSubject} from 'rxjs';
import {config} from '../../../config/config';

@Injectable()
export class ConfigurationService {
    public configBs: BehaviorSubject<any> = null;
    constructor() {
        if (window.env === 'prod') {
            this.configBs = new BehaviorSubject(config.prod);
        } else if (window.env === 'rc') {
            this.configBs = new BehaviorSubject(config.rc);
        } else if (window.env === 'lb4') {
            this.configBs = new BehaviorSubject(config.lb4);
        } else if (window.env === 'dev') {
            this.configBs = new BehaviorSubject(config.dev);
        } else {
            this.configBs = new BehaviorSubject(config.local);
        }
    }

    get apiBaseUrl() {
        return this.configBs.value.apiBaseUrl;
    }

    get storageBaseUri() {
        return this.configBs.value.storageBaseUri;
    }
}
