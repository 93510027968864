import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SpinnerService} from '../../views/common/spinner/spinner.service';
import {ErrorHandler, LoopBackAuth} from '../../sdk/core';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class ErrorHandlingService extends ErrorHandler {
    constructor(private spinner: SpinnerService, private auth: LoopBackAuth, private router: Router) {
        super();
    }
    public handleError(error: HttpResponse<any>) {
        this.spinner.HideSpinner();
        if (error.status == 401) {
            this.auth.clear();
            this.router.navigate(['/authentication/login-tep-one']);
        } else if (error.status == 500) {
        }
        return super.handleError(error);
    }
}
