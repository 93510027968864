import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
// import {HttpModule} from '@angular/http';
import {AppRoutingModule, ROUTES} from './app.routes';
import {LocationStrategy, HashLocationStrategy, PathLocationStrategy} from '@angular/common';
// import {Ng2Bs3ModalModule} from 'ng2-bs3-modal/ng2-bs3-modal';

// App views
import {MainViewModule} from './modules/account/main-view/main-view.module';
import {MinorViewModule} from './views/minor-view/minor-view.module';

// App modules/components
import {FooterModule} from './views/common/footer/footer.module';

import {AccountModule} from './modules/account/account.module';
import {AdminsModule} from './modules/admins/admins.module';
import {ErrorHandlingService} from './modules/error-handling/error-handling.service';
import {AnalyticsModule} from './modules/analytics/analytics.module';
import {DataExchangeModule} from './modules/data-exchange/data-exchange.module';
import {NgUploaderService} from 'ngx-uploader';

import {SocketDriver} from './sdk/sockets/socket.driver';
// import {Ng2TableModule} from 'ng2-table/ng2-table';
import {FormsModule} from '@angular/forms';
import {InContactAPIService} from './services/inContact/incontact-api.service';
import {LocalStorageSettingsService} from './services/inContact/local-storage-settings.service';
import {AuthenticationContextService} from './services/inContact/authentication-context.service';
import {ResourceOwnerPasswordAuthorizerService} from './services/inContact/resource-owner-password-authorizer.service';
import {SharedModule} from './shared/shared.module';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {LoopBackConfig, SDKBrowserModule} from './sdk';
import {ErrorHandler} from './sdk/core';
import {ConfigurationService} from './shared/services/configration.service';
import {SpinnerService} from './views/common/spinner/spinner.service';
import {HttpClientModule} from '@angular/common/http';
import {ModalModule as BsModalModule} from 'ngx-bootstrap/modal';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ToastrModule} from 'ngx-toastr';
import {AppToasterService} from './plugins/ngx-toastr';
import {ApiConfig} from 'src/sdk/lb.config';
import {FrontlineApiBrowserModule} from 'src/sdk';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './guards/auth.guard';

//import {LocalStorageSettingsService} from './services/inContact/local-storage-settings.service';
//import * from './sdk/models';
//import { UserApi } from './sdk';
//import { LoginComponent } from './login/login.component';

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        HttpClientModule,
        // HttpModule,
        BrowserModule,

        // Authentication
        AuthenticationModule,

        BrowserAnimationsModule,

        SharedModule,

        // Views
        MainViewModule,
        MinorViewModule,
        // SpinnerModule,

        // Modules
        // NavigationModule,
        FooterModule,
        // TopnavbarModule,
        AccountModule,
        // AgentsModule,
        AdminsModule,
        // CrmIntegrationApplicationModule,
        // ConfigurationsModule,
        AnalyticsModule,
        DataExchangeModule,
        // ShowMessagesModule,

        //Third Party
        // Ng2TableModule,
        // Ng2Bs3ModalModule,

        SDKBrowserModule.forRoot(),

        FrontlineApiBrowserModule.forRoot(),

        AppRoutingModule,

        BsModalModule.forRoot(),

        ToastrModule.forRoot(),

        NgxDatatableModule
    ],
    exports: [],
    providers: [
        AuthGuard,

        {provide: LocationStrategy, useClass: PathLocationStrategy},
        SpinnerService,
        // ShowMessagesService,
        {provide: ErrorHandler, useClass: ErrorHandlingService},
        // OldUacUserApi,
        // CompanyApi,
        // AgentApi,
        // ReportApi,
        // CrmIntegrationApplicationApi,
        // SocketConnection,
        SocketDriver,
        // SDKModels,
        // LoopBackAuth,
        // {provide: InternalStorage, useClass: StorageBrowser},
        // JSONSearchParams,
        NgUploaderService,
        InContactAPIService,
        LocalStorageSettingsService,
        AuthenticationContextService,
        ResourceOwnerPasswordAuthorizerService,

        AppToasterService,

        ConfigurationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private configrationSrv: ConfigurationService) {
        this.configrationSrv.configBs.subscribe(res => {
            LoopBackConfig.setBaseURL(this.configrationSrv.apiBaseUrl);
            ApiConfig.setBaseURL(this.configrationSrv.apiBaseUrl);

            ApiConfig.setStorageBaseURL(this.configrationSrv.storageBaseUri);
        });
        //LoopBackConfig.setBaseURL("https://frontlineagentconsole-v3-dev.azurewebsites.net");
        // LoopBackConfig.setBaseURL('http://localhost:3030');
        // LoopBackConfig.setBaseURL('https://facadmin.frontlineservicesinc.com');
        //LoopBackConfig.setBaseURL("https://frontlineagentconsole-v3-staging.azurewebsites.net");
    }
}
