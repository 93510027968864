import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {ApiConfig} from 'src/sdk/lb.config';
import {ApplicationAuthService, BaseLoopBackApi, ErrorHandler, JSONSearchParams} from 'src/sdk/core';
import {NewCompanyModel, LoginStepOne} from 'src/sdk/models';
import {FrontlineSDKModels} from '../services/custom/frontline-sdk-models';
import {LoginViewModel} from 'src/app/shared/models';
import {map} from 'rxjs/operators';
import {AnyObject} from 'src/globals';
import {SaveCompanyViewModel} from '../view-models';
import {Observable} from 'rxjs';

@Injectable()
export class NewCompaniesApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public create(data: SaveCompanyViewModel) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/create-company';
        let _routeParams: any = {};
        let _postBody: any = {data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public updateById(id: number, data: SaveCompanyViewModel) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/update-company/:id';
        let _routeParams: any = {
            id
        };
        let _postBody: any = {data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public changeStatus(id: number, status: boolean) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:id';
        let _routeParams: any = {
            id
        };
        let _postBody: any = {data: {active: status}};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public count(where: AnyObject) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/count';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        if (where) _urlParams.where = where;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public companies(filter: AnyObject) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getById(id: number, filter: AnyObject = {}): Observable<NewCompanyModel> {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:id';
        let _routeParams: any = {
            id
        };
        let _postBody: any = {};
        let _urlParams: any = {};

        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public incontactsettings(companyId: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/incontactsettings';
        let _routeParams: any = {
            companyId
        };
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public SaveIncontactSettings(companyId: number, incontactSettings: AnyObject) {
        let _method: string = 'PUT';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/incontactsettings';
        let _routeParams: any = {
            companyId
        };
        let _postBody: any = {
            incontactSettings
        };
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }
}
