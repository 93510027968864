import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration, ChartData, ChartOptions} from 'chart.js';

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit {
    @ViewChild('barChartElem') public barChartElem: ElementRef;

    private canvasCtx: CanvasRenderingContext2D;

    @Input()
    public data: ChartData;

    @Input()
    public options: ChartOptions;

    public chart: Chart;

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.canvasCtx = (this.barChartElem.nativeElement as HTMLCanvasElement).getContext('2d');
    }

    public renderChart() {
        // console.log(this.data, this.options);
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = new Chart(this.canvasCtx, {type: 'bar', data: this.data, options: this.options});
    }
}
