import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Router} from '@angular/router';
import {ShowMessagesComponent} from './show-messages.component';
import {ShowMessagesService} from './show-messages.service';

@NgModule({
    declarations: [ShowMessagesComponent],
    imports: [CommonModule, RouterModule],
    exports: [ShowMessagesComponent],
    providers: [ShowMessagesService]
})
export class ShowMessagesModule {
    constructor() {
        console.log('show messages module');
    }
}
