import {RouterModule, Routes} from '@angular/router';
import {mainViewComponent} from './modules/account/main-view/main-view.component';
import {minorViewComponent} from './views/minor-view/minor-view.component';
import {LoginComponent} from './modules/account/login-old/login.component';
import {NgModule} from '@angular/core';

export const ROUTES: Routes = [
    // Main redirect
    {path: '', redirectTo: '/account/mainView', pathMatch: 'full'},

    // App views
    // {path: 'mainView', component: mainViewComponent},
    {path: 'minorView', component: minorViewComponent},
    //{path: 'account/login', component: LoginComponent},

    // Handle all other routes
    {path: '**', component: mainViewComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
