<!-- app.component.html -->
<!--
<input type="file"
       ngFileSelect
       [options]="options"
       (onUpload)="handleUpload($event)"
       (beforeUpload)="beforeUpload($event)">
-->
<!-- drag & drop file example-->
<style>
    .file-over {
        border: dotted 3px red;
    } /* Default class applied to drop zones on over */
</style>

<div class="passwordBox animated fadeInDown">
    <div class="row">
        <div class="col-md-12">
            <div class="ibox-content">
                <h2 class="font-bold">Batch Agent Upload</h2>

                <p>
                    Download the template file. Fill data and reupload file. Please dont make any structural change to
                    the file.
                </p>

                <div class="row">
                    <div class="col-lg-12">
                        <form class="m-t" role="form">
                            <div class="form-group">
                                <a href="/agentTemplate.xlsx" target="_blank">Download Template File</a>
                            </div>
                            <div class="form-group">
                                <!-- <input type="file" class="form-control" ng2FileSelect [uploader]="uploader" required />  -->
                            </div>

                            <button type="button" (click)="upload()" class="btn btn-primary block full-width m-b">
                                Upload
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div style="background: #000; width: 200px; height: 200px;" ngFileDrop
     [options]="options"
     (onUpload)="handleUpload($event)"
     [ngClass]="{'file-over': hasBaseDropZoneOver}"
     (onFileOver)="fileOverBase($event)"
     (beforeUpload)="beforeUpload($event)">
</div>
-->
