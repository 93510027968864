import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BreadcrumbModel } from "../models";

@Injectable()
export class BreadcrumbService {

    title: string;
    icon: string;

    castBreadcrumb: Observable<Array<BreadcrumbModel>>;
    private breadcrumbsBS: BehaviorSubject<Array<BreadcrumbModel>> = new BehaviorSubject<Array<BreadcrumbModel>>([]);

    constructor(
    ) {
        this.castBreadcrumb = this.breadcrumbsBS.asObservable();
    }

    set breadcrumbs(arr: Array<BreadcrumbModel>) {
        this.breadcrumbsBS.next(arr);
    }
    set push(val: BreadcrumbModel) {
        this.breadcrumbsBS.next([
            ...this.breadcrumbsBS.value,
            val
        ]);
    }

}
