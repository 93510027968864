<app-breadcrumb></app-breadcrumb>

<!-- <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <div type="success" *ngIf="successMessage"><strong>Success!</strong> {{ successMessage }}.</div>
        <div type="error" *ngIf="errorMessage"><strong>Success!</strong> {{ errorMessage }}.</div>
    </div>
    <div class="col-lg-2"></div>
</div> -->

<div class="row animated fadeInRight" ng-controller="datatablesCtrl">
    <div class="col-md-12">
        <div class="ibox-title">
            <h5>
                inContact Settings
                <small>inContact settings can be get from inContact Central Administration Panel</small>
            </h5>

            <div ibox-tools></div>
        </div>

        <div class="ibox-content">
            <form class="form-horizontal form-horizontal" #settingsForm="ngForm" (ngSubmit)="saveSettings()" role="form">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                            <!-- <div class="form-group">
                                    <div class="col-sm-4 col-sm-offset-2">
                                        <div class="alert alert-warning">
                                            Your Api Settings are hidden, You can change these settings if you want. This can affect all the agents login so, It
                                            should be changed very carefully.
                                            <a href="javascript:void(0)" (click)="openSettingsModal(settingsModal)"
                                                ><strong>Click Here to change your Api Settings</strong></a
                                            >.
                                        </div>

                                        <div *ngIf="false && settingsModel.customNicApiSettings == true" class="alert alert-warning">
                                            <strong>Client Id:</strong> {{ settingsModel.clientId }} <br /><strong>Token Uri:</strong>
                                            {{ settingsModel.tokenServerUri }} <br /><a href="javascript:;" (click)="openSettingsModal(settingsModal)"
                                                ><strong>Click Here to change your Api Settings</strong></a
                                            >.
                                        </div>
                                    </div>
                                </div> -->
                            <div class="ibox-content">
                                <ng-container *ngIf="false">
                                    <div class="hr-line-dashed"></div>
                                </ng-container>

                                <div class="form-group">
                                    <label class="col-sm-2 control-label">
                                        Force Logout <br />
                                        <small class="text-navy">Force logout when contact in queue?</small>
                                    </label>
                                    <div class="col-sm-10">
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [(ngModel)]="settingsModel.forceLogout"
                                                    name="forceLogout"
                                                    (change)="cascadeForceLogout()"
                                                    value="true"
                                                />
                                                Force Logout
                                            </label>
                                        </div>
                                        <div>
                                            &nbsp;&nbsp;&nbsp;<label>
                                                <input
                                                    type="checkbox"
                                                    [(ngModel)]="settingsModel.endContacts"
                                                    name="endContacts"
                                                    [disabled]="!settingsModel.forceLogout"
                                                    [checked]="settingsModel.forceLogout && settingsModel.endContacts"
                                                />
                                                End Contacts?
                                            </label>
                                        </div>
                                        <div>
                                            &nbsp;&nbsp;&nbsp;<label>
                                                <input
                                                    type="checkbox"
                                                    [(ngModel)]="settingsModel.ignorePersonalQueue"
                                                    name="ignorePersonalQueue"
                                                    [disabled]="!settingsModel.forceLogout"
                                                    [checked]="settingsModel.forceLogout && settingsModel.ignorePersonalQueue"
                                                />
                                                Ignore Personal Queue
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label *ngIf="true" class="col-sm-2 control-label">
                                        Auto Reconnect <br />
                                        <small *ngIf="false" class="text-navy">Force logout when contact in queue?</small>
                                    </label>
                                    <div class="col-sm-10">
                                        <div>
                                            <label>
                                                <input type="checkbox" [(ngModel)]="settingsModel.autoReconnect" name="autoReconnect" value="false" />
                                                Auto Reconnect On Page Reload
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Login Type</label>
                                    <div class="col-sm-10">
                                        <div>
                                            <select class="form-control" name="loginType" [(ngModel)]="settingsModel.loginType" required>
                                                <option value="password">Password</option>
                                                <option value="SSO">SSO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="settingsModel.loginType === 'SSO'">
                                    <label class="col-sm-2 control-label">SSO Base Uri</label>

                                    <div class="col-sm-10">
                                        <input type="text" type="text" class="form-control" [(ngModel)]="settingsModel.ssoBaseUri" name="ssoBaseUri" required />
                                        <span class="help-block m-b-none">inContact SSO Base Uri.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="hr-line-dashed"></div>
                <div class="form-group">
                    <div class="col-sm-4 col-sm-offset-2">
                        <button class="btn btn-primary" type="submit" [disabled]="!settingsForm.valid">Save Settings</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #settingsModal>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fa fa-users"></i> Api Information</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Vendor Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        [(ngModel)]="apiModel.vendorName"
                        name="vendorName"
                        class="form-control"
                        required
                    />
                    <span class="help-block m-b-none">Vendor name of Api you got from inContact Administration Panel.</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Application Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        [(ngModel)]="apiModel.appName"
                        name="appName"
                        class="form-control"
                        required
                    />
                    <span class="help-block m-b-none">Application name of Api you got from inContact Administration Panel.</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Client Secret</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.clientSecret"
                        name="clientSecret"
                        required
                    />
                    <span class="help-block m-b-none">Client Secret you got from inContact Administration Panel.</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Token Server Uri</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.tokenServerUri"
                        name="tokenServerUri"
                        required
                        value="https://api.incontact.com/InContactAuthorizationServer/token"
                    />
                    <span class="help-block m-b-none">inContact Token Server Uri.</span>
                </div>
            </div>
        </div>

        <div class="hr-line-dashed"></div>
        <h3>Incontact Login</h3>
        <span class="help-block m-b-none">Provide your incontact login to verify the Api information is correct.</span>
        <div class="hr-line-dashed"></div>
        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Login Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.nicLoginName"
                        name="nicLoginName"
                        required
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <label class="col-sm-2 control-label">Password</label>

                <div class="col-sm-10">
                    <input
                        type="password"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.nicPassword"
                        name="nicPassword"
                        required
                    />
                </div>
            </div>
        </div>

        <div *ngIf="apiUpdateMessage" class="alert alert-warning">
            {{ apiUpdateMessage }}
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" [attr.disabled]="isUpdatingApiData ? '' : null" type="button" (click)="settingsModaalRef.hide()">Cancel</button>
        <button class="btn btn-primary" [attr.disabled]="isUpdatingApiData ? '' : null" type="submit">
            {{ isUpdatingApiData ? 'Updating....' : 'Update Api Details' }}
        </button>
    </div>
</ng-template>

<!-- <modal #settingsModal backdrop="static">
    <form class="form-horizontal form-horizontal" (submit)="saveApiSettings()">
        <modal-header [show-close]="false">
            <h4 class="modal-title"><i class="fa fa-users"></i> Api Information</h4>
        </modal-header>
        <modal-body>
            <div class="form-group">
                <label class="col-sm-2 control-label">Vendor Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        [(ngModel)]="apiModel.vendorName"
                        name="vendorName"
                        class="form-control"
                        required
                    />
                    <span class="help-block m-b-none"
                        >Vendor name of Api you got from inContact Administration Panel.</span
                    >
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">Application Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        [(ngModel)]="apiModel.appName"
                        name="appName"
                        class="form-control"
                        required
                    />
                    <span class="help-block m-b-none"
                        >Application name of Api you got from inContact Administration Panel.</span
                    >
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">Client Secret</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.clientSecret"
                        name="clientSecret"
                        required
                    />
                    <span class="help-block m-b-none">Client Secret you got from inContact Administration Panel.</span>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">Token Server Uri</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.tokenServerUri"
                        name="tokenServerUri"
                        required
                        value="https://api.incontact.com/InContactAuthorizationServer/token"
                    />
                    <span class="help-block m-b-none">inContact Token Server Uri.</span>
                </div>
            </div>

            <div class="hr-line-dashed"></div>
            <h3>Incontact Login</h3>
            <span class="help-block m-b-none"
                >Provide your incontact login to verify the Api information is correct.</span
            >
            <div class="hr-line-dashed"></div>
            <div class="form-group">
                <label class="col-sm-2 control-label">Login Name</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        type="text"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.nicLoginName"
                        name="nicLoginName"
                        required
                    />
                </div>
            </div>

            <div class="form-group">
                <label class="col-sm-2 control-label">Password</label>

                <div class="col-sm-10">
                    <input
                        type="password"
                        [attr.disabled]="isUpdatingApiData ? '' : null"
                        class="form-control"
                        [(ngModel)]="apiModel.nicPassword"
                        name="nicPassword"
                        required
                    />
                </div>
            </div>

            <div *ngIf="apiUpdateMessage" class="alert alert-warning">
                {{ apiUpdateMessage }}
            </div>
        </modal-body>
        <modal-footer [show-default-buttons]="false">
            <button
                class="btn btn-default"
                [attr.disabled]="isUpdatingApiData ? '' : null"
                type="button"
                (click)="settingsModal.close()"
            >
                Cancel
            </button>
            <button class="btn btn-primary" [attr.disabled]="isUpdatingApiData ? '' : null" type="submit">
                {{ isUpdatingApiData ? 'Updating....' : 'Update Api Details' }}
            </button>
        </modal-footer>
    </form>
</modal> -->
