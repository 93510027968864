/* tslint:disable */
import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable, throwError} from 'rxjs';
/**
 * Default error handler
 */
@Injectable()
export class ErrorHandler {
    // ErrorObservable when rxjs version < rc.5
    // ErrorObservable<string> when rxjs version = rc.5
    // I'm leaving any for now to avoid breaking apps using both versions
    public handleError(error: HttpResponse<any>) {
        console.log(error);
        return throwError(error.body || (error as any)?.error?.error || (error as any)?.error || 'Server error');
    }
}
