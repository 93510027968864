import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './login-old/login.component';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SettingsComponent} from './settings/settings.component';
import {AccountComponent} from './account.component';
import {mainViewComponent} from './main-view/main-view.component';
import {AuthGuard} from 'src/app/guards/auth.guard';

const accountRoutes: Routes = [
    {path: 'account/login', component: LoginComponent},
    // { path: "account/registration", component: RegistrationComponent },
    {path: 'account/forgotpassword', component: PasswordRecoveryComponent},
    {path: 'account/changepassword', component: ChangePasswordComponent},
    // {path: 'account/settings', component: SettingsComponent},
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'mainView',
                pathMatch: 'prefix'
                // canActivate: [AuthGuard]
            },
            {
                path: 'mainView',
                canActivate: [AuthGuard],
                component: mainViewComponent
            },
            {
                path: 'settings',
                canActivate: [AuthGuard],
                component: SettingsComponent
            },

            {
                path: 'agents',
                loadChildren: () => import('./../agents/agents.module').then(m => m.AgentsModule)
            },
            {
                path: 'crm-applications',
                loadChildren: () => import('./../crm-integration-applications/crm-integration-applications.module').then(m => m.CrmIntegrationApplicationModule)
            },
            {
                path: 'manage-companies',
                loadChildren: () => import('./manage-companies/manage-companies.module').then(m => m.ManageCompaniesModule)
            },
            {
                path: 'email-notifications',
                loadChildren: () => import('./email-notifications/email-notifications.module').then(m => m.EmailNotificationsModule)
            },
            {
                path: 'crm-templates',
                loadChildren: () => import('./crm-templates/crm-templates.module').then(m => m.CrmTemplatesModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(accountRoutes)],
    exports: [RouterModule]
})
export class AccountRoutingModule {}
