import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminsComponent} from './admins.component';
import {AdminsRoutingModule} from './admins-routing.module';

//import {DataTableModule} from "angular2-datatable";
// import {DataTableModule} from "angular2-serverpagination-datatable";
import {FormsModule} from '@angular/forms';
// import { Ng2Bs3ModalModule } from 'ng2-bs3-modal/ng2-bs3-modal';
// import { Ng2TableModule, NG_TABLE_DIRECTIVES  } from 'ng2-table/ng2-table';
// import { PaginationModule } from 'ng2-bootstrap';
import {AdminFormComponent} from './admin-form/admin-form.component';
import {DetailComponent} from './detail/detail.component';
import {CreateAdminComponent} from './create-admin/create-admin.component';
import {EditAdminComponent} from './edit-admin/edit-admin.component';
import {SetPasswordComponent} from './set-password/set-password.component';

@NgModule({
    imports: [
        CommonModule,
        AdminsRoutingModule,
        FormsModule
        // Ng2Bs3ModalModule,
        // Ng2TableModule,
        // PaginationModule,
        // DataTableModule
    ],
    declarations: [
        AdminsComponent,
        AdminFormComponent,
        DetailComponent,
        CreateAdminComponent,
        EditAdminComponent,
        SetPasswordComponent
    ]
})
export class AdminsModule {}
