import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DataExchangeComponent} from './data-exchange.component';
//import { RegistrationComponent }  from './registration/registration.component';
//import { PasswordRecoveryComponent }  from './password-recovery/password-recovery.component';

const dataExchangeRoutes: Routes = [
    {path: 'dataexchange', component: DataExchangeComponent}
    //{ path: 'account/registration', component: RegistrationComponent },
    //{ path: 'account/forgotpassword', component: PasswordRecoveryComponent }
];

@NgModule({
    imports: [RouterModule.forChild(dataExchangeRoutes)],
    exports: [RouterModule]
})
export class DataExchangeRoutingModule {}
