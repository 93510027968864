import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
    // styles: ['.app-spinner:{background-color:#000;}'],
})
export class SpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
