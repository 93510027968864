import {Component, OnInit} from '@angular/core';
import {ShowMessagesService} from './show-messages.service';

@Component({
    selector: 'app-show-messages',
    templateUrl: './show-messages.component.html'
    // styleUrls: ['./show-messages.component.css']
})
export class ShowMessagesComponent implements OnInit {
    errorMessage: string;
    successMessage: string;

    constructor(private showMessageService: ShowMessagesService) {
        this.showMessageService.message.subscribe(message => {
            this.setMessage(message.type, message.text);
        });
    }

    ngOnInit() {}

    setMessage(type: string, message: string) {
        let me = this;
        if (type == 'error') {
            this.errorMessage = message;
            setTimeout(function () {
                me.errorMessage = null;
            }, 6500);
        } else if (type == 'success') {
            this.successMessage = message;
            setTimeout(function () {
                me.successMessage = null;
            }, 6500);
        }
    }
}
