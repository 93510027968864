<div class="spinner-box">
    <div
        class="app-spinner sk-spinner sk-spinner-double-bounce"
        style="position: fixed; bottom: 0%; right: 0%; width: 200px; height: 200px"
    >
        <div class="sk-double-bounce1"></div>
        <div class="sk-double-bounce2"></div>
    </div>
    <div></div>
</div>
