export class CrmTemplateModel {
    id?: number;
    title: string;
    crmType: string;
    active: boolean;
    created?: string;

    constructor(data?: Partial<CrmTemplateModel>) {
        Object.assign(this, data);
    }
}
