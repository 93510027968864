import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DataExchangeComponent} from './data-exchange.component';
import {DataExchangeRoutingModule} from './data-exchange.routing';
// import {FileUploadModule} from 'ng2-file-upload';

@NgModule({
    imports: [CommonModule, DataExchangeRoutingModule],
    declarations: [DataExchangeComponent]
})
export class DataExchangeModule {
    constructor() {}
}
