import { Injectable } from '@angular/core';

import { AuthenticationContextModel } from '.././models/authentication-context.model';

@Injectable()
export class LocalStorageSettingsService {
  public token: string;
  private authenticationContext: AuthenticationContextModel;

  constructor() {

  }

  saveSettings(authenticationContext: AuthenticationContextModel) {
    return localStorage.setItem("ic_authContext", JSON.stringify(authenticationContext));
  }

  clearSettings() {
    localStorage.removeItem("ic_authContext");
  }


  getSettings(): AuthenticationContextModel {
    let authenticationContext = <AuthenticationContextModel>JSON.parse(localStorage.getItem("ic_authContext"));
    return authenticationContext;
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    return <string>localStorage.getItem(key);
  }

  setSessionstorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getSessionstorage(key: string): string {
    return <string>sessionStorage.getItem(key);
  }

}