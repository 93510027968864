<div class="passwordBox animated fadeInDown">
    <div class="row">
        <div *ngIf="!passwordResetSuccess" class="col-md-12">
            <div class="ibox-content">
                <h2 class="font-bold">Forgot password</h2>

                <p>Enter your email address and your password will be reset and emailed to you.</p>

                <div class="row">
                    <div class="col-lg-12">
                        <form class="m-t" role="form" (ngSubmit)="forgotPassword()">
                            <div class="form-group">
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    [(ngModel)]="model.email"
                                    placeholder="Email address"
                                    required=""
                                />
                            </div>

                            <button type="submit" class="btn btn-primary block full-width m-b">
                                Send new password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="passwordResetSuccess" class="col-md-12">
            <div class="ibox-content">
                <h2 class="font-bold">Success!</h2>

                <p>
                    Your password reset request has been accepted. You will get an email with instruction about next
                    steps. Thank you!
                </p>

                <div class="row">
                    <div class="col-lg-12">
                        <a [routerLink]="['/authentication/login-tep-one']">Go to login page</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />
    <div class="row">
        <div class="col-md-6">Frontline Services Inc.</div>
        <div class="col-md-6 text-right">
            <small>© 2017</small>
        </div>
    </div>
</div>
