import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {ShowMessagesModule} from '../../views/common/show-messages/show-messages.module';
import {AuthenticationRoutingModule} from './authentication.routing';
import {AuthenticationComponent} from './component/authentication.component';
import {LoginStepOneComponent} from './login-step-one/login-step-one.component';
import {LoginStepTwoComponent} from './login-step-two/login-step-two.component';
import {RegistrationComponent} from './registration/registration.component';
import {AuthenticationService} from './services/authentication.service';

@NgModule({
    imports: [SharedModule, ShowMessagesModule, AuthenticationRoutingModule],
    declarations: [AuthenticationComponent, RegistrationComponent, LoginStepOneComponent, LoginStepTwoComponent],
    providers: [AuthenticationService]
})
export class AuthenticationModule {
    constructor() {}
}
