export class SaveCompanyViewModel {
    companyId?: number;
    // ownerId?: number;
    title: string;
    companyEmail: string;
    firstName: string;
    lastName: string;
    email: string;
    billingAPI: boolean | null = null;
    inContactBusinessUnit: string | null = null;
    crmApplicationType: string | null = null;
    webRTCEnabled: boolean = false;
    credentialsType: string | null = null;
    crmTemplateId: number | null = null;

    constructor(data?: Partial<SaveCompanyViewModel>) {
        Object.assign(this, data);
    }
}
