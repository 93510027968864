import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {ApiConfig} from 'src/sdk/lb.config';
import {ApplicationAuthService, BaseLoopBackApi, ErrorHandler, JSONSearchParams} from 'src/sdk/core';
import {LoginStepOne} from 'src/sdk/models';
import {FrontlineSDKModels} from '../services/custom/frontline-sdk-models';
import {LoginViewModel} from 'src/app/shared/models';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthencationApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public LoginStepOne(data: LoginStepOne) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/account/login-step-one';
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }

    public Login(data: {}) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/account/login';
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null).pipe(
            map((response: any) => {
                response.ttl = parseInt(response.ttl);
                response.rememberMe = true;
                this.auth.setToken(response);
                return response;
            })
        );
        return result;
    }

    public LoginWithCode(data: {}, redirect_uri: string) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/account/login-with-code?redirect_uri=${redirect_uri}`;
        let _routeParams: any = {};
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null).pipe(
            map((response: any) => {
                response.ttl = parseInt(response.ttl);
                response.rememberMe = true;
                this.auth.setToken(response);
                return response;
            })
        );
        return result;
    }

    public Logout() {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/account/logout';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null).pipe(
            map((response: any) => {
                // response.ttl = parseInt(response.ttl);
                // response.rememberMe = true;
                // this.auth.setToken(response);
                return response;
            })
        );
        return result;
    }
}
