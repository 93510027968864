import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthencationApi} from 'src/sdk/api-services';

import {UacUserApi} from '../../../sdk/fac-integration-api';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';
import {AuthenticationService} from '../services/authentication.service';

@Component({
    selector: 'app-login-step-one',
    templateUrl: './login-step-one.component.html'
})
export class LoginStepOneComponent implements OnInit, AfterViewInit {
    constructor(
        public authenticationSrv: AuthenticationService,
        public uacUserApi: UacUserApi,
        public router: Router,
        public messagesSrv: ShowMessagesService,
        public spinnerSrv: SpinnerService,
        public authenticationApi: AuthencationApi
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {}

    Next() {
        this.spinnerSrv.ShowSpinner();
        // this.uacUserApi.loginStepOne({email: this.authenticationSrv.loginData.username}).subscribe(
        this.authenticationApi.LoginStepOne({email: this.authenticationSrv.loginData.username}).subscribe(
            res => {
                this.spinnerSrv.HideSpinner();
                this.authenticationSrv.publicProfile = res.agent;

                if (res?.agent?.company?.inContactSettings) {
                    const company = res.agent.company;

                    var icSettings = company.inContactSettings;
                    this.authenticationSrv.incontactSettings = icSettings;

                    if (this.authenticationSrv.isSSOLogin) {
                        this.authenticationSrv.LoginWithSSO();
                    } else {
                        this.router.navigate(['authentication', 'login-step-two']);
                    }
                } else {
                    this.messagesSrv.message.next({
                        type: 'error',
                        text: 'Invalid account data.'
                    });
                }
            },
            error => {
                // console.log(error);
                this.spinnerSrv.HideSpinner();
                this.messagesSrv.message.next({
                    type: 'error',
                    text: error.message || 'Unable to login. Please try again later.'
                });
            }
        );
    }
}
