<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-sm-4">
        <h2>Admin detail</h2>
        <ol class="breadcrumb">
            <li>
                <a [routerLink]="['/']">Home</a>
            </li>
            <li class="active">
                <strong>Admin detail</strong>
            </li>
        </ol>
    </div>
</div>

<div class="row">
    <div class="col-lg-9">
        <div class="wrapper wrapper-content animated fadeInRight">
            <div class="ibox">
                <div class="ibox-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="m-b-md">
                                <a
                                    *ngIf="dataModel.active"
                                    (click)="updateAdminStatus(false)"
                                    class="btn btn-white btn-xs pull-right"
                                    >Deactivate Account</a
                                >
                                <a
                                    *ngIf="!dataModel.active"
                                    (click)="updateAdminStatus(true)"
                                    class="btn btn-white btn-xs pull-right"
                                    >Activate</a
                                >
                                <a
                                    *ngIf="dataModel.active"
                                    (click)="setPasswordModal()"
                                    class="btn btn-white btn-xs pull-right"
                                    >Set Password</a
                                >
                                <a
                                    [routerLink]="['/admins', dataModel.id, 'edit']"
                                    class="btn btn-white btn-xs pull-right"
                                    >Edit details</a
                                >
                                <h2>{{ dataModel.firstName }} {{ dataModel.lastName }}</h2>
                            </div>
                            <dl class="dl-horizontal">
                                <dt>Status:</dt>
                                <dd>
                                    <span *ngIf="dataModel.active" class="label label-primary">Active</span
                                    ><span *ngIf="!dataModel.active" class="label">Inactive</span>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">
                            <dl class="dl-horizontal">
                                <dt>Email:</dt>
                                <dd>{{ dataModel.email }}</dd>
                                <dt>Frist Name:</dt>
                                <dd>{{ dataModel.firstName }}</dd>
                                <dt>Last Name:</dt>
                                <dd>{{ dataModel.lastName }}</dd>
                            </dl>
                        </div>
                        <div class="col-lg-7" id="cluster_info">
                            <dl class="dl-horizontal">
                                <dt>Last Updated:</dt>
                                <dd>{{ dataModel.dateModified }}</dd>
                                <dt>Created:</dt>
                                <dd>{{ dataModel.dateCreated }}</dd>
                            </dl>
                        </div>
                    </div>

                    <div class="row m-t-sm">
                        <div class="col-lg-12">
                            <div class="panel blank-panel ui-tab"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <modal #modal>
    <modal-header [show-close]="true">
        <h4 class="modal-title"><i class="fa fa-users"></i> Administrator</h4>
    </modal-header>
    <modal-body>
        <app-set-password #setPasswordForm> </app-set-password>
    </modal-body>
    <modal-footer [show-default-buttons]="false">
        <button class="btn btn-primary" (click)="setPassword()" type="button">Set Password</button>
    </modal-footer>
</modal> -->
