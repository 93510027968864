import {Injectable} from '@angular/core';
import {Company} from '../../models/Company';
import {Agent} from '../../models/Agent';
import {Email} from '../../models/Email';
import {ApiApplication} from '../../models/ApiApplication';
import {UacUser} from '../../models/UacUser';
import {Container} from '../../models/Container';
import {CrmIntegrationApplication} from '../../models/CrmIntegrationApplication';
import {Service} from '../../models/Service';
import {AgentSession} from '../../models/AgentSession';
import {Report} from '../../models/Report';

export interface Models {
    [name: string]: any;
}

@Injectable()
export class SDKModels {
    private models: Models = {
        Company: Company,
        Agent: Agent,
        Email: Email,
        ApiApplication: ApiApplication,
        UacUser: UacUser,
        Container: Container,
        CrmIntegrationApplication: CrmIntegrationApplication,
        Service: Service,
        AgentSession: AgentSession,
        Report: Report
    };

    public get(modelName: string): any {
        return this.models[modelName];
    }

    public getAll(): Models {
        return this.models;
    }

    public getModelNames(): string[] {
        return Object.keys(this.models);
    }
}
