/* tslint:disable */
import {Agent, CrmIntegrationApplication} from '../../index';

declare var Object: any;
export interface CompanyInterface {
    active: boolean;
    contactSettings?: any;
    inContactSettings?: any;
    contactMappings?: any;
    title: string;
    email: string;
    phone?: string;
    inContactBusinessUnit?: string;
    defualtCRM?: string;
    apiKey: string;
    protected?: Array<any>;
    dynamicsSettings?: any;
    id?: number;
    agents?: Agent[];
    crmApplicatoins?: CrmIntegrationApplication[];
}

export class Company implements CompanyInterface {
    'active': boolean;
    'contactSettings': any;
    'inContactSettings': any;
    'contactMappings': any;
    'title': string;
    'email': string;
    'phone': string;
    'inContactBusinessUnit': string;
    'defualtCRM': string;
    'apiKey': string;
    'protected': Array<any>;
    'dynamicsSettings': any;
    'id': number;
    agents: Agent[];
    crmApplicatoins: CrmIntegrationApplication[];
    constructor(data?: CompanyInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `Company`.
     */
    public static getModelName() {
        return 'Company';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of Company for dynamic purposes.
     **/
    public static factory(data: CompanyInterface): Company {
        return new Company(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'Company',
            plural: 'Companies',
            path: 'Companies',
            idName: 'id',
            properties: {
                active: {
                    name: 'active',
                    type: 'boolean',
                    default: true
                },
                contactSettings: {
                    name: 'contactSettings',
                    type: 'any'
                },
                inContactSettings: {
                    name: 'inContactSettings',
                    type: 'any'
                },
                contactMappings: {
                    name: 'contactMappings',
                    type: 'any'
                },
                title: {
                    name: 'title',
                    type: 'string'
                },
                email: {
                    name: 'email',
                    type: 'string'
                },
                phone: {
                    name: 'phone',
                    type: 'string'
                },
                inContactBusinessUnit: {
                    name: 'inContactBusinessUnit',
                    type: 'string'
                },
                defualtCRM: {
                    name: 'defualtCRM',
                    type: 'string',
                    default: ''
                },
                apiKey: {
                    name: 'apiKey',
                    type: 'string'
                },
                protected: {
                    name: 'protected',
                    type: 'Array&lt;any&gt;'
                },
                dynamicsSettings: {
                    name: 'dynamicsSettings',
                    type: 'any'
                },
                id: {
                    name: 'id',
                    type: 'number'
                }
            },
            relations: {
                agents: {
                    name: 'agents',
                    type: 'Agent[]',
                    model: 'Agent',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'companyId'
                },
                crmApplicatoins: {
                    name: 'crmApplicatoins',
                    type: 'CrmIntegrationApplication[]',
                    model: 'CrmIntegrationApplication',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'companyId'
                }
            }
        };
    }
}
