import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Router} from '@angular/router';

import {SearchDropdown} from 'src/app/shared/components/search-dropdown/search-dropdown.component';
import {OldUacUserApi} from '../../../sdk';
//import {TopnavbarComponent} from "./topnavbar.component.ts";
import {TopnavbarComponent} from './topnavbar.component';

@NgModule({
    declarations: [TopnavbarComponent, SearchDropdown],
    imports: [CommonModule, RouterModule, FormsModule],
    exports: [TopnavbarComponent]
})
export class TopnavbarModule {
    constructor(private userService: OldUacUserApi, private router: Router) {}
    logout() {
        let me = this;
        alert('logout');
        this.userService.logout().subscribe(result => {
            me.router.navigate(['/authentication/login-tep-one']);
        });
    }
}
