import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {AdminFormComponent} from '../admin-form/admin-form.component';

@Component({
    selector: 'app-create-admin',
    templateUrl: './create-admin.component.html',
    styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {
    constructor(private router: Router, private messagesService: ShowMessagesService) {}

    ngOnInit() {
        this.AdminFormComponent.loadModel(0);
    }

    @ViewChild('adminForm')
    AdminFormComponent: AdminFormComponent;

    saveAdmin() {
        if (this.AdminFormComponent.IsValid()) {
            this.AdminFormComponent.saveAdmin().subscribe(
                result => {
                    console.log(result);
                    this.router.navigate(['/admins/list']);
                },
                error => {
                    this.messagesService.message.next({type: 'error', text: error});
                }
            );
        }
    }
}
