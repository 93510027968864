import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';

import {Router, ActivatedRoute, Params} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BreadcrumbService} from 'src/app/shared/services/breadcrumb.service';
import {NewCompaniesApi} from 'src/sdk/api-services';
import {ApplicationAuthService} from 'src/sdk/core';
// import {ModalComponent} from 'ng2-bs3-modal/components/modal';
import {CompanyApi, OldUacUserApi} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    defaulttokenServerUri = 'https://api.incontact.com/InContactAuthorizationServer/token';

    settingsModel: any = {};
    iconClass: string = '';
    pageDescription: string = '';

    apiUpdateMessage: string = null;

    isUpdatingApiData: boolean = false;

    settingsModaalRef: BsModalRef;

    companyId: number | null = null;

    constructor(
        private companyService: CompanyApi,
        private userApi: OldUacUserApi,
        private spinnerService: SpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modalSrv: BsModalService,
        private breadcrumbSrv: BreadcrumbService,
        @Inject(ApplicationAuthService) protected applicationAuthSrv: ApplicationAuthService,
        @Inject(NewCompaniesApi) public newCompaniesApi: NewCompaniesApi
    ) {
        this.breadcrumbSrv.title = 'Incontact Settings';
        this.breadcrumbSrv.breadcrumbs = [
            {
                Route: '/account',
                Title: 'Account'
            },
            {
                Route: '/account/settings',
                Title: 'Incontact Settings'
            }
        ];

        this.spinnerService.ShowSpinner();
        this.apiModel.tokenServerUri = this.defaulttokenServerUri;
    }

    dynamicsSettingsModel: any = {};

    apiModel: any = {};

    errorMessage = null;
    successMessage = null;

    // @ViewChild('settingsModal')
    // modal: ModalComponent;

    ngOnInit() {
        setTimeout(() => {
            this.companyId = this.applicationAuthSrv.getCurrentCompanyId();
            this.loadData();
        }, 500);
    }

    loadData() {
        this.spinnerService.ShowSpinner();
        // let companyId = this.userApi.getCachedCurrent().companyId;
        const user = this.applicationAuthSrv.getCurrentUserData();
        console.log(user);

        this.newCompaniesApi
            .incontactsettings(this.companyId)
            .subscribe(res => {
                this.settingsModel = res ?? {};
                if (!this.settingsModel.ssoBaseUri) {
                    this.settingsModel.ssoBaseUri = null;
                }
                if (!this.settingsModel.loginType) {
                    this.settingsModel.loginType = 'password';
                }
            })
            .add(() => {
                this.spinnerService.HideSpinner();
            });

        // this.companyService
        //     .getIncontactSettings(companyId)

        //     .subscribe(result => {
        //         this.settingsModel = result;
        //         if (!this.settingsModel.ssoBaseUri) {
        //             this.settingsModel.ssoBaseUri = null;
        //         }
        //         if (!this.settingsModel.loginType) {
        //             this.settingsModel.loginType = 'password';
        //         }
        //         //this.parseIncontactSettings(result);
        //     })
        //     .add(() => {
        //         this.spinnerService.HideSpinner();
        //     });

        // this.companyService
        //     .getDynamicsSettings(companyId)

        //     .subscribe(result => {
        //         this.dynamicsSettingsModel = result;
        //     })
        //     .add(() => {
        //         this.spinnerService.HideSpinner();
        //     });
    }

    parseIncontactSettings(): any {
        //this.settingsModel = settings;
        if (this.settingsModel.customNicApiSettings == true && `${this.settingsModel.clientId}`.length > 0) {
            var cidSplit = `${this.settingsModel.clientId}`.split('@');
            if (cidSplit.length == 2)
                this.apiModel = {
                    vendorName: cidSplit[0],
                    appName: cidSplit[1],
                    tokenServerUri: this.settingsModel.tokenServerUri
                };
        } else {
            this.apiModel = {tokenServerUri: this.defaulttokenServerUri};
        }
    }

    saveSettings() {
        this.spinnerService.ShowSpinner();

        this.newCompaniesApi
            .SaveIncontactSettings(this.companyId, this.settingsModel)

            .subscribe(result => {
                this.setMessage('success', 'Settings are saved successfully!');
            })
            .add(() => {
                this.spinnerService.HideSpinner();
            });

        // this.companyService
        //     .setIncontactSettings(companyId, this.settingsModel)

        //     .subscribe(result => {
        //         this.setMessage('success', 'Settings are saved successfully!');
        //     })
        //     .add(() => {
        //         this.spinnerService.HideSpinner();
        //     });
    }

    // saveDynamicsSettings() {
    //     let companyId = this.userApi.getCachedCurrent().companyId;
    //     this.spinnerService.ShowSpinner();
    //     this.companyService
    //         .setDynamicsSettings(companyId, this.dynamicsSettingsModel)

    //         .subscribe(result => {
    //             this.setMessage('success', 'Settings are saved successfully!');
    //         })
    //         .add(() => {
    //             this.spinnerService.HideSpinner();
    //         });
    // }

    setMessage(type: string, message: string) {
        let me = this;
        if (type == 'error') {
            this.errorMessage = message;
            setTimeout(function () {
                me.errorMessage = null;
            }, 5000);
        } else if (type == 'success') {
            this.successMessage = message;
            setTimeout(function () {
                me.successMessage = null;
            }, 5000);
        }
    }

    cascadeForceLogout() {
        if (!this.settingsModel.forceLogout) {
            this.settingsModel.endContacts = false;
            this.settingsModel.ignorePersonalQueue = false;
        }
    }
    openSettingsModal(template: TemplateRef<any>) {
        this.settingsModaalRef = this.modalSrv.show(template, {
            ignoreBackdropClick: true
        });
        // this.modal.open();
        this.parseIncontactSettings();
    }

    saveApiSettings() {
        console.log(this.apiModel);
        console.log('submiting api');
        this.isUpdatingApiData = true;
        let companyId = this.userApi.getCachedCurrent().companyId;
        this.companyService
            .setIncontactApiSettings(companyId, this.apiModel)

            .subscribe(
                r => {
                    this.settingsModel = r;
                    // this.modal.close();
                    this.settingsModaalRef.hide();
                },
                err => {
                    this.setApiErrorMessage(err.message);
                }
            )
            .add(() => {
                this.isUpdatingApiData = false;
            });
    }

    setApiErrorMessage(msg) {
        var me = this;
        this.apiUpdateMessage = msg;
        setTimeout(() => {
            me.apiUpdateMessage = null;
        }, 5000);
    }
}
