import {HttpResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {Observable} from 'rxjs';

import {OldUacUserApi, UacUser} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

declare var jQuery: any;

@Component({
    selector: 'app-admin-form',
    templateUrl: './admin-form.component.html'
    //styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {
    public dataModelDefault = {};
    public dataModel: any = null;
    public editMode = false;
    constructor(private adminApi: OldUacUserApi, private spinner: SpinnerService) {
        this.dataModel = this.dataModelDefault;
    }
    public saveAdmin(): Subject<UacUser> {
        var bhv = new Subject<UacUser>();
        if (!this.editMode) {
            this.spinner.ShowSpinner();
            this.dataModel.username = this.dataModel.email;
            this.adminApi
                .create(this.dataModel)

                .subscribe(
                    (result: UacUser) => {
                        bhv.next(result);
                    },
                    error => {
                        this.handleError(error);
                        bhv.error(error);
                    }
                )
                .add(() => {
                    this.spinner.HideSpinner();
                });
        } else {
            this.spinner.ShowSpinner();
            this.adminApi
                .updateAttributes(this.dataModel.id, this.dataModel)

                .subscribe(
                    result => {
                        bhv.next(result);
                    },
                    error => {
                        this.handleError(error);
                        bhv.error(error);
                    }
                )
                .add(() => {
                    this.spinner.HideSpinner();
                });
        }

        return bhv;
    }
    public IsValid() {
        var $myForm = jQuery('#admin-form');
        if (!$myForm[0].checkValidity()) {
            $myForm.find('button').click();
            return false;
        }
        return true;
    }
    public onSubmit() {
        if (this.IsValid()) {
        }
        return false;
    }
    /*
  public Reset(){
    this.dataModel = {type: 'n'};
  }
  */
    ngOnInit() {}

    loadModel(id): Subject<any> {
        let dataSub = new Subject<any>();
        this.spinner.ShowSpinner();
        this.adminApi
            .findById(id)

            .subscribe((result: any) => {
                this.dataModel = this.dataModelDefault;
                this.dataModel.firstName = result.firstName;
                this.dataModel.lastName = result.lastName;
                this.dataModel.email = result.email;
                this.dataModel.id = result.id;
                this.dataModel.active = result.active;
                this.editMode = true;
                dataSub.next(this.dataModel);
            })
            .add(() => {
                this.spinner.HideSpinner();
            });
        return dataSub;
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof HttpResponse) {
            const body = error.body || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return throwError(errMsg);
    }
}
