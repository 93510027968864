<div class="row border-bottom">
    <nav class="navbar navbar-static-top white-bg d-flex" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header flex-grow-1">
            <div class="d-flex align-items-center">
                <div>
                    <a class="minimalize-styl-2 btn btn-primary" (click)="toggleNavigation()"><i class="fa fa-bars"></i> </a>
                </div>
                <!-- <div class="p-relative">
                    <label class="label-over">Select Company</label>
                    <select
                        name="selectCompany"
                        id="selectCompany"
                        class="form-control"
                        [(ngModel)]="currentCompanyId"
                        (change)="companyClick($event)"
                        style="min-width: 250px"
                    >
                        <ng-container *ngFor="let company of companiesList">
                            <option [value]="company.id" [selected]="currentCompanyId === company.id">{{company.title}}</option>
                        </ng-container>
                    </select>
                </div> -->

                <div class="p-relative" *ngIf="applicationAuth.isSuperAdmin">
                    <search-dropdown
                        class="d-block"
                        [items]="companiesList"
                        [size]="'small'"
                        [img]="'product'"
                        [label]="'title'"
                        [uid]="'id'"
                        [(ngModel)]="currentCompanyId"
                        (afterChange)="companyClick($event)"
                        style="min-width: 250px"
                    >
                    </search-dropdown>
                    <!-- {{currentCompanyId}} -->
                </div>
            </div>
            <!--
            <form role="search" class="navbar-form-custom" method="post" action="#">
                <div class="form-group">
                    <input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">
                </div>
            </form>
            -->
        </div>
        <ul class="nav navbar-top-links navbar-right ml-auto">
            <li>
                <a href="javascript:;" (click)="logout()"> <i class="fa fa-sign-out"></i> Log out </a>
            </li>
        </ul>
    </nav>
</div>
