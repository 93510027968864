<div class="passwordBox animated fadeInDown">
    <div class="row">

        <div class="col-md-12">
            <div class="ibox-content">

                <h2 class="font-bold">Change password</h2>

                <p>
                    Enter your current password and your new password.
                </p>

                <div class="row">

                    <div class="col-lg-12">
                        <form class="m-t" role="form" (ngSubmit)="changePassword()">
                            <div class="form-group">
                                <input type="password" class="form-control" [(ngModel)]="changePasswordModel.currentPassword" name="currentPassword" placeholder="Current Password" required="">
                            </div>
                            <p>&nbsp;</p>
                            <div class="form-group">
                                <input type="password" class="form-control" [(ngModel)]="changePasswordModel.newPassword" name="newPassword" placeholder="New Password" required="">
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" [(ngModel)]="changePasswordModel.confirmPassword" name="confirmPassword" placeholder="Confirm Password" required="">
                            </div>

                            <button type="submit" class="btn btn-primary block full-width m-b">Change Password</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-md-6">
            Copyright Example Company
        </div>
        <div class="col-md-6 text-right">
            <small>© 2014-2015</small>
        </div>
    </div>
</div>