import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';

import {AgentSession} from '../../models/AgentSession';
import {SocketConnection} from '../../../sockets/socket.connections';
import {Agent} from '../../models/Agent';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `AgentSession` model.
 */
@Injectable()
export class AgentSessionApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * Fetches belongsTo relation company.
     *
     * @param {any} id AgentSession id
     *
     * @param {boolean} refresh
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `AgentSession` object.)
     * </em>
     */
    public getCompany(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/AgentSessions/:id/company';
        let _routeParams: any = {
            id: id
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `AgentSession`.
     */
    public getModelName() {
        return 'AgentSession';
    }
}
