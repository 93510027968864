<nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
            <li class="nav-header">
                <div class="dropdown profile-element">
                    <!-- {{applicationAuth.getCurrentUserData() | json}} -->
                    <a *ngIf="applicationAuth.getCurrentUserData()" data-toggle="dropdown" class="dropdown-toggle" href="javascript:void(0)">
                        <span class="block m-t-xs">
                            <strong class="font-bold"
                                >{{applicationAuth.getCurrentUserData().firstName}} {{applicationAuth.getCurrentUserData().lastName}}</strong
                            >
                        </span>
                        <!-- <span class="text-muted text-xs block">
                            {{applicationAuth.getCurrentCompany ? applicationAuth.getCurrentCompany.title : ''}}
                            <b class="caret"></b>
                        </span> -->
                    </a>
                    <!-- <ul class="dropdown-menu animated fadeInRight m-t-xs">
                        <ng-container *ngFor="let company of companiesList">
                            <li *ngIf="company.id !== applicationAuth.getCurrentCompany.id" (click)="companyClick(company)">
                                <a href="javascript:void(0)"> {{company.title}} </a>
                            </li>
                        </ng-container>
                    </ul> -->
                </div>
                <div class="logo-element">FAC</div>
            </li>
            <li [ngClass]="{active: activeRoute('mainView')}">
                <a [routerLink]="['./mainView']"><i class="fa fa-th-large"></i> <span class="nav-label">Dashboard</span></a>
            </li>
            <li [ngClass]="{active: activeRoute('account/settings')}">
                <a [routerLink]="['/account/settings']"><i class="fas fa-cogs"></i> <span class="nav-label">InContact Settings</span> </a>
            </li>
            <li [ngClass]="{active: activeRoute('agents/list')}">
                <a [routerLink]="['/account/agents']"><i class="fa fa-users"></i> <span class="nav-label">Agents List</span> </a>
            </li>
            <li [ngClass]="{active: activeRoute('account/agents/import')}" *ngIf="isMyCompany">
                <a [routerLink]="['/account/agents/import']"><i class="fa fa-user-plus"></i> <span class="nav-label">Manage Agents</span> </a>
            </li>
            <!-- <li *ngIf="false" [ngClass]="{active: activeRoute('minorView')}">
                <a [routerLink]="['/admins/list']"><i class="fa fa-desktop"></i> <span class="nav-label">Admins</span> </a>
            </li> -->
            <li [ngClass]="{active: activeRoute('crm-applications')}">
                <a [routerLink]="['/account/crm-applications/list']"
                    ><i class="fas fa-users-cog"></i> <span class="nav-label">Contact Settings Profiles</span>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('manage-companies')}" *ngIf="applicationAuth.isSuperAdmin">
                <a [routerLink]="['/account/manage-companies/list']"><i class="fa fa-window-restore"></i> <span class="nav-label">Manage Companies</span> </a>
            </li>

            <li *ngIf="applicationAuth.isSuperAdmin" [ngClass]="{active: activeRoute('/account/email-notifications')}">
                <a href="javascript:void(0)">
                    <i class="fa fa-bell"></i>
                    <span class="nav-label">Email Notifications</span>
                    <span class="fa arrow"></span>
                </a>

                <ul class="nav nav-second-level collapse" aria-expanded="true">
                    <li [ngClass]="{active: activeRoute('/account/email-notifications/sent-list')}">
                        <a [routerLink]="['/account/email-notifications']">All Notifications</a>
                    </li>
                    <li [ngClass]="{active: activeRoute('/account/email-notifications/templates')}">
                        <a [routerLink]="['/account/email-notifications/templates']">Notification Templates</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="applicationAuth.isSuperAdmin" [ngClass]="{active: activeRoute('crm-templates')}">
                <a [routerLink]="['/account/crm-templates/list']"><i class="fas fa-users-cog"></i> <span class="nav-label">CRM Templates</span> </a>
            </li>

            <li *ngIf="applicationAuth.isSuperAdmin" [ngClass]="{active: activeRoute('/account/reports')}">
                <a href="javascript:void(0)">
                    <i class="fa fa-bell"></i>
                    <span class="nav-label">Reports</span>
                    <span class="fa arrow"></span>
                </a>

                <ul class="nav nav-second-level collapse" aria-expanded="true">
                    <li [ngClass]="{active: activeRoute('reports/frontline-billing-report')}">
                        <a [routerLink]="['/account/reports/frontline-billing-report']">Frontline Billing Report</a>
                    </li>
                    <li [ngClass]="{active: activeRoute('reports/nic-billing-report')}">
                        <a [routerLink]="['/account/reports/nic-billing-report']">NIC Billing Report</a>
                    </li>
                </ul>
            </li>

            <!--<li [ngClass]="{active: activeRoute('settings')}">
                <a><i class="fa fa-sliders"></i> <span class="nav-label">Settings</span> </a>
                <ul class="nav nav-second-level collapse in">
                    <li><a [routerLink]="['./configurations/inboundcalls']"><i class="fa fa-phone-square"></i> <span class="nav-label">Inbound Call</span></a></li>
                    <li><a [routerLink]="['./configurations/outboundcalls']"><i class="fa fa-phone"></i> <span class="nav-label">Outbound Call</span></a></li>
                    <li><a [routerLink]="['./configurations/workitem']"><i class="fa fa-tasks"></i> <span class="nav-label">Work Item</span></a></li>
                    <li><a [routerLink]="['./configurations/chat']"><i class="fa fa-comments"></i> <span class="nav-label">Chat</span></a></li>
                    <li><a [routerLink]="['./configurations/inboundemails']"><i class="fa fa-envelope-square"></i> <span class="nav-label">Inbound Email</span></a></li>
                    <li><a [routerLink]="['./configurations/outboundemails']"><i class="fa fa-envelope-o"></i> <span class="nav-label">Outbound Email</span></a></li>
                    <li><a [routerLink]="['./configurations/voice-mails']"><i class="fa fa-file-audio-o"></i> <span class="nav-label">Voice Mail</span></a></li>
                </ul>
            </li>-->
            <li *ngIf="false" [ngClass]="{active: activeRoute('settings')}">
                <a [routerLink]="['./reports']"><i class="fa fa-copy"></i> <span class="nav-label">Reports</span> </a>
            </li>
            <li *ngIf="false" [ngClass]="{active: activeRoute('settings')}">
                <a [routerLink]="['./dataexchange']"><i class="fa fa-cloud-upload"></i> <span class="nav-label">Data Import/Export</span> </a>
            </li>
        </ul>
    </div>
</nav>
