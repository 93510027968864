import {Component, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {SetPasswordComponent} from '../set-password/set-password.component';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {OldUacUserApi} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
    public dataModel: any = {};

    // @ViewChild('modal')
    // modal: ModalComponent;

    @ViewChild(SetPasswordComponent)
    setPasswordComponent: SetPasswordComponent;
    constructor(
        private adminApi: OldUacUserApi,
        private spinner: SpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.params.subscribe((params: Params) => {
            let userId = params['id'];
            console.log(userId);
            this.adminApi
                .findById(userId)

                .subscribe(result => {
                    this.dataModel = result;
                    this.setPasswordComponent.dataModel = result;
                })
                .add(() => {
                    this.spinner.HideSpinner();
                });
        });
    }

    updateAdminStatus(status) {
        this.spinner.ShowSpinner();
        this.adminApi
            .updateAttributes(this.dataModel.id, {active: status})

            .subscribe(result => {
                this.dataModel = result;
                this.setPasswordComponent.dataModel = result;
            })
            .add(() => {
                this.spinner.HideSpinner();
            });
    }

    setPasswordModal() {
        // this.modal.open();
    }

    setPassword() {
        this.setPasswordComponent.setPassword().subscribe(result => {
            // this.modal.close();
        });
    }
}
