import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent }    from './analytics.component';
//import { RegistrationComponent }  from './registration/registration.component';
//import { PasswordRecoveryComponent }  from './password-recovery/password-recovery.component';

const analyticsRoutes: Routes = [
  { path: 'analytics',  component: AnalyticsComponent },
  //{ path: 'account/registration', component: RegistrationComponent },
  //{ path: 'account/forgotpassword', component: PasswordRecoveryComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(analyticsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AnalyticsRoutingModule { }