<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-sm-4">
        <h2>Admins list</h2>
        <ol class="breadcrumb">
            <li>
                <a [routerLink]="['/']">Home</a>
            </li>
            <li class="active">
                <strong>Admin list</strong>
            </li>
        </ol>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="wrapper wrapper-content animated fadeInUp">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>All admins associated to this account</h5>

                    <div class="ibox-tools">
                        <!-- <a [routerLink]="['/admins/add']" class="btn btn-primary btn-xs">Create new admin</a> -->
                    </div>
                </div>
                <div class="ibox-content">
                    <div class="row m-b-sm m-t-sm">
                        <div class="col-md-1">
                            <button
                                (click)="loadData()"
                                type="button"
                                id="loading-example-btn"
                                class="btn btn-white btn-sm"
                            >
                                <i class="fa fa-refresh"></i> Refresh
                            </button>
                        </div>
                        <div class="col-md-11">
                            <div class="input-group">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    class="input-sm form-control"
                                    [(ngModel)]="searchQuery"
                                />
                                <span class="input-group-btn">
                                    <button type="button" class="btn btn-sm btn-primary" (click)="loadData()">
                                        Go!
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-4 m-b-xs">
                            <div data-toggle="buttons" class="btn-group">
                                <label class="btn btn-sm btn-white active" (click)="activeFilter(true)">
                                    <input type="radio" id="option1" name="options" /> Active
                                </label>
                                <label class="btn btn-sm btn-white" (click)="activeFilter(false)">
                                    <input type="radio" id="option2" name="options" /> Inactive
                                </label>
                                <label *ngIf="false" class="btn btn-sm btn-white">
                                    <input type="radio" id="option3" name="options" /> All
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="project-list">
                        <!-- <table class="table table-hover" [mfData]="rows" #mf="mfDataTable" [mfRowsOnPage]="5">-->
                        <!-- <table class="table table-hover"
                        [mfData]="rows" #mf="mfDataTable"
                            [mfRowsOnPage]="viewmodel.rowsOnPage" [mfActivePage]="viewmodel.activePage"
                            (mfOnPageChange)="onPageChange($event)" [(mfAmountOfRows)]="viewmodel.itemsTotal"
                            [(mfSortBy)]="viewmodel.sortBy" [(mfSortOrder)]="viewmodel.sortOrder"
                            (mfSortOrderChange)="onSortOrder($event)">
                            <thead>
                                <tr>
                                    <th class="project-title">Status</th>
                                    <th class="project-title">
                                        <mfDefaultSorter by="FirstName">Name</mfDefaultSorter>
                                    </th>
                                    <th class="project-title">
                                        <mfDefaultSorter by="Email">Email </mfDefaultSorter>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of mf.data">
                                    <td class="project-status">
                                        <span class="label label-primary" *ngIf="item.active">Active</span>
                                        <span class="label" *ngIf="!item.active">Inactive</span>
                                    </td>
                                    <td class="project-title">
                                        <a ui-sref="project_detail">{{item.firstName}} {{item.lastName}}</a>
                                        <br />
                                        <small>Created {{item.dateCreated | date: 'MM/dd/yyyy'}}</small>
                                    </td>
                                    <td class="project-completion">
                                        <small>{{item.email}}</small>
                                    </td>
                                    <td class="project-actions">
                                        <ng-container *ngIf="false">
                                            <a [routerLink]="['/admins', item.id, 'detail']" *ngIf="false"
                                                class="btn btn-white btn-sm">
                                                <i class="fa fa-folder"></i> View </a>
                                            <a [routerLink]="['/admins', item.id, 'edit']" class="btn btn-white btn-sm">
                                                <i class="fa fa-pencil"></i> Edit </a>
                                        </ng-container>
                                    </td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="12">
                                        <mfBootstrapPaginator [rowsOnPageSet]="[25,50,100]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
