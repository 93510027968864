import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, Router} from '@angular/router';

import * as array from 'lodash/array';
import * as lang from 'lodash/lang';
import {MyUserProfile} from 'src/sdk';
import {ApplicationAuthService} from 'src/sdk/core';
import {AuthenticationContextService} from '../services/inContact/authentication-context.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    curentUser: MyUserProfile = null;

    loaded = false;
    constructor(private auth: ApplicationAuthService, private router: Router, private authenticationContextSrv: AuthenticationContextService) {
        this.curentUser = new MyUserProfile(this.auth.getCurrentUserData());
        // this.userApi.findById<any>(this.auth.getCurrentUserId(), { include: 'roles' }).subscribe(user => {
        //     if (user !== null) {
        //         let roleArray: Array<String>;
        //         roleArray = [];
        //         for (let i = 0; i < user.roles.length; i++) {
        //             roleArray.push(user.roles[i].name);
        //         }
        //         user.rolesArray = [];
        //         user.rolesArray = roleArray;
        //         this.auth.setUser(user);
        //     }
        // }, error => {});
        // this.userApi.getAccessTokens(this.auth.getCurrentUserId()).subscribe(tokens => {
        // }, error => {
        //     console.log('from check login guard', error);
        //     this.auth.clear();
        //     this.router.navigate(['/login']);
        // });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url: string = state.url;
        return this.CheckLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    canLoad(route: Route) {
        const url = `/${route.path}`;
        return this.CheckLogin(url);
    }

    CheckLogin(url: string) {
        const token = this.auth.getToken();
        if (token) {
            let expires = token.Expires;
            // console.log(token.ttl, expires, new Date().getTime());

            if (expires - new Date().getTime() > 0) {
                return true;
            }
        }

        this.auth.clear();

        const sessionId = 123456789;

        const navigationExtras: NavigationExtras = {
            queryParams: {session_id: sessionId},
            fragment: 'anchor'
        };

        this.router.navigate(['/authentication/login-step-one'], navigationExtras);
        return false;
    }
}
