import {Company} from '../../index';

declare var Object: any;
export interface CrmIntegrationApplicationInterface {
    applicationType: string;
    title: string;
    description?: string;
    crmAccountSettings?: any;
    contactSettings?: any;
    contactMappings?: any;
    active?: boolean;
    id?: number;
    companyId?: number;
    company?: Company;
}

export class CrmIntegrationApplication implements CrmIntegrationApplicationInterface {
    'applicationType': string;
    'title': string;
    'description': string;
    'crmAccountSettings': any;
    'contactSettings': any;
    'contactMappings': any;
    'active': boolean;
    'id': number;
    'companyId': number;
    company: Company;
    constructor(data?: CrmIntegrationApplicationInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `CrmIntegrationApplication`.
     */
    public static getModelName() {
        return 'CrmIntegrationApplication';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of CrmIntegrationApplication for dynamic purposes.
     **/
    public static factory(data: CrmIntegrationApplicationInterface): CrmIntegrationApplication {
        return new CrmIntegrationApplication(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'CrmIntegrationApplication',
            plural: 'CrmIntegrationApplication',
            path: 'CrmIntegrationApplication',
            idName: 'id',
            properties: {
                applicationType: {
                    name: 'applicationType',
                    type: 'string'
                },
                title: {
                    name: 'title',
                    type: 'string'
                },
                description: {
                    name: 'description',
                    type: 'string'
                },
                crmAccountSettings: {
                    name: 'crmAccountSettings',
                    type: 'any'
                },
                contactSettings: {
                    name: 'contactSettings',
                    type: 'any'
                },
                contactMappings: {
                    name: 'contactMappings',
                    type: 'any'
                },
                active: {
                    name: 'active',
                    type: 'boolean',
                    default: true
                },
                id: {
                    name: 'id',
                    type: 'number'
                },
                companyId: {
                    name: 'companyId',
                    type: 'number'
                }
            },
            relations: {
                company: {
                    name: 'company',
                    type: 'Company',
                    model: 'Company',
                    relationType: 'belongsTo',
                    keyFrom: 'companyId',
                    keyTo: 'id'
                }
            }
        };
    }
}
