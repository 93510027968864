import {HttpClient} from '@angular/common/http';
import {Injectable, Inject} from '@angular/core';
import {BaseLoopBackApi, ApplicationAuthService, JSONSearchParams} from '../core';
import {ApiConfig} from '../lb.config';
import {FrontlineSDKModels} from '../services';
import {FrontlineBillingReportViewModel, NicBillingReportViewModel} from '../view-models';

@Injectable()
export class NewReportsApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public dashboardReport(companyId: number, year: number, month: number, timezoneDifferenceHours?: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/reports/dashboard';

        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        if (typeof year !== 'undefined' && !!year) _urlParams.year = year;
        if (typeof month !== 'undefined' && month != null) _urlParams.month = month;
        if (typeof companyId !== 'undefined' && !!companyId) _urlParams.companyId = companyId;
        if (typeof timezoneDifferenceHours !== 'undefined' && timezoneDifferenceHours != null) _urlParams.timezoneDifferenceHours = timezoneDifferenceHours;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public detailedAgentLoginReport(companyId: number, year: number, month: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/reports/detailed-agent-login-report';

        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        if (typeof year !== 'undefined' && !!year) _urlParams.year = year;
        if (typeof month !== 'undefined' && month != null) _urlParams.month = month;
        if (typeof companyId !== 'undefined' && !!companyId) _urlParams.companyId = companyId;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public frontlineBillingReport(year: number, month: number) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/reports/frontline-billing-report';

        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};

        if (typeof year !== 'undefined' && !!year) _urlParams.year = year;
        if (typeof month !== 'undefined' && month != null) _urlParams.month = month;

        let result = this.request<Array<FrontlineBillingReportViewModel>>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public nicBillingReport(date: string) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/reports/nic-billing-report';

        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {
            date
        };

        let result = this.request<Array<NicBillingReportViewModel>>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }
}
