import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';

import {Email} from '../../models/Email';
import {SocketConnection} from '../../../sockets/socket.connections';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `Email` model.
 */
@Injectable()
export class EmailApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Email`.
     */
    public getModelName() {
        return 'Email';
    }
}
