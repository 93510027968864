import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {ApiConfig} from 'src/sdk/lb.config';
import {ApplicationAuthService, BaseLoopBackApi, ErrorHandler, JSONSearchParams} from 'src/sdk/core';
import {LoginStepOne} from 'src/sdk/models';
import {FrontlineSDKModels} from '../services/custom/frontline-sdk-models';
import {LoginViewModel} from 'src/app/shared/models';
import {map} from 'rxjs/operators';
import {AnyObject} from 'src/globals';

@Injectable()
export class NewAgentsApi extends BaseLoopBackApi {
    getModelName(): string {
        throw new Error('Method not implemented.');
    }
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(FrontlineSDKModels) protected models: FrontlineSDKModels,
        @Inject(ApplicationAuthService) protected auth: ApplicationAuthService,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams
    ) {
        super(http, models, auth, searchParams);
    }

    public count(companyId: number, where: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/companies/:companyId/agents/count`;
        let _routeParams: any = {
            companyId
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (where) _urlParams.where = where;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getAgents(companyId: number, filter: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/companies/:companyId/agents`;
        let _routeParams: any = {
            companyId
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public getAgentById(companyId: number, id: number, filter: AnyObject = {}) {
        let _method: string = 'GET';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + `/companies/:companyId/agents/:id`;
        let _routeParams: any = {id, companyId};
        let _postBody: any = {};
        let _urlParams: any = {};
        if (filter) _urlParams.filter = filter;

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public updateById(companyId: number, id: number, data: AnyObject) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/agents/:id';
        let _routeParams: any = {id, companyId};
        let _postBody: any = {agent: data};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public DisableAdminAccess(companyId: number, id: number) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/agents/:id/disable-admin-access';
        let _routeParams: any = {id, companyId};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public EnableAdminAccess(companyId: number, id: number) {
        let _method: string = 'PATCH';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/agents/:id/enable-admin-access';
        let _routeParams: any = {id, companyId};
        let _postBody: any = {};
        let _urlParams: any = {};

        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);

        return result;
    }

    public Import(companyId: number, data: {}) {
        let _method: string = 'POST';
        let _url: string = ApiConfig.getPath() + '/' + ApiConfig.getApiVersion() + '/companies/:companyId/agents/import';
        let _routeParams: any = {
            companyId
        };
        let _postBody: any = {
            data: data
        };
        let _urlParams: any = {};
        let result = this.request<any>(_method, _url, _routeParams, _urlParams, _postBody, null);
        return result;
    }
}
