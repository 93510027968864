import {Component, OnInit, Inject, ReflectiveInjector} from '@angular/core';
import {Observable} from 'rxjs';
// import {Response} from '@angular/http';
import {Router} from '@angular/router';
import {InContactAPIService} from '../../../services/inContact/incontact-api.service';
import {OldUacUserApi} from '../../../sdk';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';
import {LoopBackAuth} from '../../../sdk/core';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
    //styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    userService: OldUacUserApi;
    loginModel: any = {};
    errorMessage: any = {};
    spinner: SpinnerService = null;
    router: Router = null;

    constructor(
        userService: OldUacUserApi,
        spinner: SpinnerService,
        router: Router,
        private mssagesService: ShowMessagesService,
        private inContactAPIService: InContactAPIService
    ) {
        this.userService = userService;
        this.spinner = spinner;
        this.router = router;
        console.log(userService);
        console.log(inContactAPIService);
    }

    ngOnInit() {
        //var injector = ReflectiveInjector.resolveAndCreate([UserApi]);
        //var test = injector.get(UserApi);
        this.spinner.HideSpinner();
    }

    ngAfterViewInit() {
        console.log(this.userService.getCachedCurrent());
        this.spinner.HideSpinner();
    }

    Login_new() {
        this.userService.findOne({where: {username: this.loginModel.username}}).subscribe(
            r => {
                console.log(r);
            },
            err => {
                console.log(err);
            }
        );
    }

    Login() {
        this.spinner.ShowSpinner();
        this.userService
            .login(this.loginModel, 'user', true) //.map(response => response)

            .subscribe(
                result => {
                    this.inContactAPIService.authenticationContextService.saveAuthContext(result.ic_token_data);
                    this.router.navigate(['/']);
                },
                error => {
                    this.handleError(error);
                    this.errorMessage = <any>error;
                    console.log(error);
                    console.log('hello this was error message');
                    this.mssagesService.message.next({
                        type: 'error',
                        text: 'Login failed. Please check your user name and password!'
                    });
                }
            )
            .add(() => {
                this.spinner.HideSpinner();
            });
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof HttpResponse) {
            const body = error.body || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }
}
