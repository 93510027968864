export class SendEmailNotificationViewModel {
    templateId: number;
    sendToType: string;
    companyId?: number;
    agentsIds: Array<number>;
    agentsEmails: Array<string>;
    subject: string;
    body: string;

    constructor(data?: Partial<SendEmailNotificationViewModel>) {
        Object.assign(this, data);
    }
}
