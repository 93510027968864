import {Injectable} from '@angular/core';

export interface Models {
    [name: string]: any;
}

@Injectable()
export class FrontlineSDKModels {
    private models: Models = {
        //
    };

    public get(modelName: string): any {
        return this.models[modelName];
    }

    public getAll(): Models {
        return this.models;
    }

    public getModelNames(): string[] {
        return Object.keys(this.models);
    }
}
