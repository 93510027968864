export class EmailTemplateModel {
    id?: number;
    emailType: string;
    from?: string;
    to?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    body?: string;
    active: boolean;
    created?: string;

    constructor(data?: Partial<EmailTemplateModel>) {
        Object.assign(this, data);
    }
}
