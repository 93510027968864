<!-- Left navigation bar -->
<navigation style="position: fixed" *ngIf="!isFullPage()"></navigation>

<!-- Main page wrapper -->
<div id="page-wrapper" class="gray-bg">
    <!-- Top navigation -->
    <topnavbar *ngIf="!isFullPage()"></topnavbar>
    <!-- <app-spinner class="loading-overlay" *ngIf="spinner.SpinnerVisible"> </app-spinner> -->
    <!-- Main view/routes wrapper-->
    <app-show-messages #showMessages></app-show-messages>

    <div class="pt-5">
        <router-outlet></router-outlet>
    </div>

    <!-- Footer -->
    <footer *ngIf="!isFullPage()"></footer>
</div>
<!-- End page wrapper-->
