import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SpinnerService} from '../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html'
    //styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
    private FullPagesList = [
        '/authentication',
        '/register',
        '/authentication/login-step-one',
        '/authentication/login-step-two',
        '/account/login',
        '/account/registration',
        '/account/forgotpassword'
    ];
    private PublicPages = [
        '/authentication',
        '/register',
        '/authentication/login-step-one',
        '/authentication/login-step-two',
        '/account/login',
        '/account/registration',
        '/account/forgotpassword'
    ];

    constructor(public spinner: SpinnerService, private spinnerService: SpinnerService, private router: Router) {}

    ngOnInit() {
        this.spinnerService.HideSpinner();
    }

    public isFullPage(): boolean {
        let currentRoute = this.router.createUrlTree([this.router.url]).toString();
        let retVal: boolean = this.FullPagesList.indexOf(currentRoute) > -1;
        //console.log(currentRoute);
        return retVal;
    }
}
