export const config = {
    local: {
        apiBaseUrl: 'http://localhost:3030',
        storageBaseUri: 'https://frontlineagentconsole-v3-storage.azurewebsites.net/'
    },
    dev: {
        apiBaseUrl: 'https://frontlineagentconsole-v3-dev.azurewebsites.net',
        storageBaseUri: 'https://frontlineagentconsole-v3-storage.azurewebsites.net/'
    },
    lb4: {
        apiBaseUrl: 'https://frontlineagentconsole-v3-lb4.azurewebsites.net',
        storageBaseUri: 'https://frontlineagentconsole-v3-storage.azurewebsites.net/'
    },
    rc: {
        apiBaseUrl: 'https://frontlineagentconsole-v3-rc.azurewebsites.net',
        storageBaseUri: 'https://frontlineagentconsole-v3-storage.azurewebsites.net/'
    },
    prod: {
        apiBaseUrl: 'https://facadmin.frontlineservicesinc.com',
        storageBaseUri: 'https://frontlineagentconsole-v3-storage.azurewebsites.net/'
    }
};
