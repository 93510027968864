import {ParseTreeResult} from '@angular/compiler';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map, retry} from 'rxjs/operators';
import {AnyObject, parseJwt} from 'src/globals';
import {AuthencationApi} from 'src/sdk/api-services';
import {ApplicationAuthService} from 'src/sdk/core';
import {OldUacUserApi} from '../../../sdk';
import {LoopBackAuth} from '../../../sdk/core';
import {AccessTokenPayloadModel, IdTokenPayloadModel, UacUserApi} from '../../../sdk/fac-integration-api';
import {InContactAPIService} from '../../../services/inContact/incontact-api.service';
import {LoginViewModel} from '../../../shared/models';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';
import {AuthenticationContextModel} from 'src/app/services/models/authentication-context.model';

@Injectable()
export class AuthenticationService {
    loginData: LoginViewModel;
    public publicProfile: AnyObject = {};
    incontactSettings: any;

    SSO_Callback: string = 'https://fac.frontlineservicesinc.com/sso/login';

    constructor(
        @Inject(ApplicationAuthService) protected applicationAuth: ApplicationAuthService,
        @Inject(OldUacUserApi) private userApi: OldUacUserApi,
        @Inject(UacUserApi) private uacUserApi: UacUserApi,
        @Inject(ShowMessagesService) public messagesSrv: ShowMessagesService,
        @Inject(SpinnerService) public spinnerSrv: SpinnerService,
        @Inject(AuthencationApi) public authenticationApi: AuthencationApi,
        private router: Router,
        private inContactAPIService: InContactAPIService
    ) {
        this.loginData = new LoginViewModel();

        window.addEventListener('message', event => {
            if (event.data && event.data.type === 'code') {
                console.log(event.data.code);

                this.spinnerSrv.ShowSpinner();
                this.authenticationApi
                    .LoginWithCode({code: event.data.code, email: this.loginData.username}, this.SSO_Callback)
                    .pipe(
                        map((res: any) => {
                            const accessTokenPayload = parseJwt<AccessTokenPayloadModel>(res.ic_token_data.access_token);
                            const idTokenPayload = parseJwt<IdTokenPayloadModel>(res.ic_token_data.id_token);
                            const authContext = new AuthenticationContextModel({
                                access_token: res.ic_token_data.access_token,
                                id_token: res.ic_token_data.id_token,
                                refresh_token: res.ic_token_data.refresh_token,
                                agent_id: `${accessTokenPayload.icAgentId}`,
                                scope: accessTokenPayload.icScope,
                                refresh_token_server_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/InContactAuthorizationServer/Token`,
                                resource_server_base_uri: `https://api-${idTokenPayload.icClusterId}.${idTokenPayload.icDomain}/inContactAPI/`,
                                expires_in: res.ic_token_data.expires_in,
                                token_type: res.ic_token_data.token_type
                            });

                            res.ic_token_data = authContext;

                            this.applicationAuth.setToken(res);

                            return res;
                        })
                    )
                    .subscribe(
                        result => {
                            if (result.UserProfile) {
                                const user = result.UserProfile;
                                // const roles = result.roles;

                                user.company = result.company;

                                this.applicationAuth.setUser(result.UserProfile);
                            }
                            this.inContactAPIService.authenticationContextService.saveAuthContext(result.ic_token_data);
                            this.router.navigate(['/account']);
                        },
                        err => {
                            // console.log('login with code error: ', err);
                            this.messagesSrv.message.next({
                                type: 'error',
                                text: err.message
                            });
                        }
                    )
                    .add(() => {
                        this.spinnerSrv.HideSpinner();
                    });
            }
        });
    }

    LoginWithSSO() {
        const url =
            `${this.ssoBaseUri}/auth/authorize?` +
            `client_id=${this.clientId}` +
            `&login_hint=${this.publicProfile.email}` +
            `&redirect_uri=${this.SSO_Callback}` +
            `&scope=openid` +
            `&response_type=code`;

        const result = window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
    }

    LoginWithCredentials() {
        this.spinnerSrv.ShowSpinner();
        // this.userApi
        //     .login(this.loginData, 'user', true) //.map(response => response)

        this.authenticationApi
            .Login({email: this.loginData.username, password: this.loginData.password})
            .subscribe(
                result => {
                    if (result.UserProfile) {
                        const user = result.UserProfile;
                        // const roles = result.roles;

                        user.company = result.company;

                        this.applicationAuth.setUser(result.UserProfile);
                    }
                    this.inContactAPIService.authenticationContextService.saveAuthContext(result.ic_token_data);
                    this.router.navigate(['/account']);
                },
                error => {
                    // this.errorMessage = <any>error;
                    // console.log(error);
                    // console.log('hello this was error message');
                    this.messagesSrv.message.next({
                        type: 'error',
                        text: 'Login failed. Please check your user name and password!'
                    });
                }
            )
            .add(() => {
                this.spinnerSrv.HideSpinner();
            });
    }

    get isSSOLogin() {
        if (this.publicProfile.useCompanySSOSettings === false) {
            if (this.publicProfile.settings) {
                if (this.publicProfile.settings.loginType === 'SSO' && !!this.publicProfile.settings.ssoBaseUri) {
                    return true;
                } else if (this.publicProfile.settings.loginType === 'password') {
                    return false;
                }
            }
        }
        if (this.incontactSettings) {
            if (this.incontactSettings.loginType === 'SSO' && !!this.incontactSettings.ssoBaseUri) {
                return true;
            }
        }

        return false;
    }

    public get ssoBaseUri() {
        if (this.publicProfile.useCompanySSOSettings === false) {
            if (this.publicProfile?.settings?.loginType === 'SSO' && !!this.publicProfile?.settings?.ssoBaseUri) {
                return this.publicProfile?.settings?.ssoBaseUri;
            }
        } else {
            if (this.incontactSettings?.loginType === 'SSO') {
                return this.incontactSettings.ssoBaseUri;
            }
        }
        return '';
    }

    public get clientId() {
        const settings = this.publicProfile?.company?.settings ?? [];

        return settings.find(x => x.key === 'incontact-client-id')?.value ?? '';
    }
}
