<div class="wrapper wrapper-content animated fadeInRight">

    <div class="row">
        <div class="ibox-title">
            <h5>Administrator Account
                <small>Provide details for administrator account</small>
            </h5>
            <div ibox-tools></div>
        </div>
        <div class="ibox-content">
            <form class="form-horizontal form-horizontal" #adminForm="ngForm" id="admin-form" (ngSubmit)="onSubmit()" role="form">


                <div class="row">
                    <div class="col-lg-12">
                        <div class="ibox float-e-margins">



                            <div class="ibox-content">


                                <div class="form-group">
                                    <label class="col-sm-2 control-label">First Name</label>

                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" placeholder="First Name (Required)" required name="firstName" [(ngModel)]="dataModel.firstName">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="hr-line-dashed"></div>
                                    <label class="col-sm-2 control-label">Last Name</label>

                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" placeholder="Last Name (Required)" name="lastName" required [(ngModel)]="dataModel.lastName">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="hr-line-dashed"></div>
                                    <label class="col-sm-2 control-label">Email</label>

                                    <div class="col-sm-10">
                                        <input type="email" class="form-control" placeholder="Email (Required)" required name="email" [(ngModel)]="dataModel.email">
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="!editMode">
                                    <div class="hr-line-dashed"></div>
                                    <label class="col-sm-2 control-label">Password</label>

                                    <div class="col-sm-10">
                                        <input type="password" class="form-control" [disabled]="editMode" placeholder="Password (Required)" required name="email"
                                            [(ngModel)]="dataModel.password">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <button style="display: none;" type="submit">Register</button>
            </form>
        </div>

    </div>
</div>