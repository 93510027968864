import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {Chart, registerables} from 'chart.js';
import {BarChartComponent} from './bar-chart/bar-chart.component';

Chart.register(...registerables);

@NgModule({
    declarations: [BarChartComponent],
    imports: [CommonModule],
    exports: [BarChartComponent]
})
export class ChartJsModule {
    constructor() {}
}
