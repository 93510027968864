import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OldUacUserApi} from '../../../sdk';
import {LoopBackAuth} from '../../../sdk/core';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html'
    //styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    changePasswordModel: any = {};
    constructor(
        private userService: OldUacUserApi,
        private spinner: SpinnerService,
        private auth: LoopBackAuth,
        private router: Router
    ) {}
    changePassword() {
        let me = this;
        console.log(this.changePasswordModel);
        me.spinner.ShowSpinner();
        this.userService
            .changePassword(this.changePasswordModel)
            .subscribe(result => {
                console.log(result);
                me.auth.clear();
                me.router.navigate(['/authentication/login-step-one']);
            })
            .add(() => {
                me.spinner.HideSpinner();
            });
    }
    ngOnInit() {}

    ngAfterViewInit() {
        this.spinner.HideSpinner();
    }
}
