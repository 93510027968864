<div class="loginColumns animated fadeInDown">
    <div class="row">

        <div class="col-md-6">
            <h2 class="font-bold">Welcome to Frontline Agent Console Administration Panel</h2>

            <p>
                Manage agents. Create, Activate or Deactivate agent accounts.
            </p>

            <p>
                Manage your integration settings universally for your account.
            </p>

            <p>
                View Reporting. Check agent activity and session reports
            </p>

            <p *ngIf="false">
                <small>It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                    essentially unchanged.</small>
            </p>

        </div>
        <div class="col-md-6">
            <div class="ibox-content">
                <form class="m-t" role="form" action="index.html">
                    <div class="form-group">
                        <input type="email" [(ngModel)]="loginModel.username"
                            (ngModelChange)="loginModel.username = $event" name="username" class="form-control"
                            placeholder="Email" required="">
                    </div>
                    <div class="form-group">
                        <input type="password" [(ngModel)]="loginModel.password"
                            (ngModelChange)="loginModel.password = $event" name="password" class="form-control"
                            class="form-control" placeholder="Password" required="">
                    </div>
                    <div class="form-group">
                        <input type="checkbox" [(ngModel)]="loginModel.agreeToTerms" name="agreeToTerms"
                            class="form-control" class="" required> <small>By Logging in, you are agreeing to the 
                                <!-- <a
                                target="_blank"
                                href="http://www.frontlineservicesinc.com/assets/downloads/FAC-Zendesk%20End%20User%20License%20Agreement.pdf">Terms
                                &amp; Conditions</a> -->
                                <a target="_blank" href="https://frontline.group/frontline-agent-console-end-user-license-agreement/">Terms &amp; Conditions</a>
                                set forth in the EULA.</small>
                    </div>
                    <button type="button" class="btn btn-primary block full-width m-b"
                        [disabled]="!loginModel.agreeToTerms || !loginModel.username || !loginModel.password"
                        (click)="Login()">Login</button>

                    <a href="https://docs.google.com/document/d/e/2PACX-1vR2stqyUiQEl-ne0OmWRkZ-k0mzyaWQ4QsmG_LVx1xt1-j4SE5OTLnHIPiaDnp318MStW7UMfAEXdzP/pub#h.hxu0yxry035q"
                        target="_blank">
                        <small>Forgot password?</small>
                    </a>

                    <p class="text-muted text-center">
                        <small>Do not have an account?</small>
                    </p>
                    <a class="btn btn-sm btn-white btn-block" [routerLink]="['/account/registration']">Create an
                        account</a>
                </form>
                <p class="m-t">
                    <small>Frontline Agent Console Administration &copy; 2017-2018</small>
                </p>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-6">
            Copyright Frontline Services Inc.
        </div>
        <div class="col-md-6 text-right">
            <small>© 2017-2018</small>
        </div>
    </div>
</div>