<!--<div type="success" *ngIf="successMessage">
        <strong>Success!</strong> {{successMessage}}.
    </div>
    <div type="error" *ngIf="errorMessage">
        <strong>Success!</strong> {{errorMessage}}.
    </div>-->

<div class="alert alert-success" role="alert" *ngIf="successMessage">
    <strong>Success!</strong> {{ successMessage }}.
</div>
<div class="alert alert-danger" *ngIf="errorMessage"><strong>Error!</strong> {{ errorMessage }}.</div>
