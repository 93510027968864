/* tslint:disable */
/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
 *  imports: [
 *    BrowserModule,
 *    routing,
 *    SDK[Browser|Node|Native]Module.forRoot()
 *  ],
 *  declarations: [ AppComponent ],
 *  bootstrap:    [ AppComponent ]
 * })
 * export class AppModule { }
 *
 **/
import {JSONSearchParams} from './core/search.params';
import {ErrorHandler} from './core/error.service';
import {LoopBackAuth} from './core/auth.service';
import {LoggerService} from './old/services/custom/logger.service';
import {SDKModels} from './old/services/custom/SDKModels';
import {InternalStorage, SDKStorage} from './storage/storage.swaps';
import {HttpClientModule} from '@angular/common/http';
// import {HttpModule} from '@angular/http';
import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {CookieBrowser} from './storage/cookie.browser';
import {StorageBrowser} from './storage/storage.browser';
import {SocketBrowser} from './sockets/socket.browser';
import {SocketDriver} from './sockets/socket.driver';
import {SocketConnection} from './sockets/socket.connections';
import {RealTime} from './core/real.time';
import {CompanyApi} from './old/services/custom/Company';
import {AgentApi} from './old/services/custom/Agent';
import {EmailApi} from './old/services/custom/Email';
import {ApiApplicationApi} from './old/services/custom/ApiApplication';
import {OldUacUserApi} from './old/services/custom/UacUser';
import {ContainerApi} from './old/services/custom/Container';
import {CrmIntegrationApplicationApi} from './old/services/custom/CrmIntegrationApplication';
import {ServiceApi} from './old/services/custom/Service';
import {AgentSessionApi} from './old/services/custom/AgentSession';
import {ReportApi} from './old/services/custom/Report';
import {UacUserApi} from './fac-integration-api';
/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
        // HttpModule
    ],
    declarations: [],
    exports: [],
    providers: [SocketConnection]
})
export class SDKBrowserModule {
    static forRoot(
        internalStorageProvider: any = {
            provide: InternalStorage,
            useClass: CookieBrowser
        }
    ): ModuleWithProviders<SDKBrowserModule> {
        return {
            ngModule: SDKBrowserModule,
            providers: [
                LoopBackAuth,
                LoggerService,
                JSONSearchParams,
                SDKModels,
                RealTime,
                CompanyApi,
                AgentApi,
                EmailApi,
                ApiApplicationApi,
                OldUacUserApi,
                UacUserApi,
                ContainerApi,
                CrmIntegrationApplicationApi,
                ServiceApi,
                AgentSessionApi,
                ReportApi,
                internalStorageProvider,
                {provide: InternalStorage, useClass: StorageBrowser},
                {provide: SocketDriver, useClass: SocketBrowser}
            ]
        };
    }
}
/**
 * Have Fun!!!
 * - Jon
 **/
export * from './old/models/index';
export * from './old/services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export {CookieBrowser} from './storage/cookie.browser';
export {StorageBrowser} from './storage/storage.browser';
