<form class="m-t" novalidate #stepTwoForm="ngForm" (ngSubmit)="Login()">
    <div class="form-group">
        <label for="passowrd">Password <span class="text-danger">*</span></label>
        <input
            id="passowrd"
            type="password"
            [(ngModel)]="authenticationSrv.loginData.password"
            name="password"
            class="form-control"
            placeholder="Password"
            required
        />
    </div>

    <div class="text-right" style="margin-bottom: 10px">
        <a
            href="https://docs.google.com/document/d/e/2PACX-1vR2stqyUiQEl-ne0OmWRkZ-k0mzyaWQ4QsmG_LVx1xt1-j4SE5OTLnHIPiaDnp318MStW7UMfAEXdzP/pub#h.hxu0yxry035q"
            target="_blank"
        >
            <small>Forgot password?</small>
        </a>
    </div>

    <div class="form-group">
        <input
            type="checkbox"
            [(ngModel)]="authenticationSrv.loginData.agreeToTerms"
            name="agreeToTerms"
            class="form-control"
            class=""
            required
        />
        <small
            >By Logging in, you are agreeing to the
            <!-- <a
                target="_blank"
                href="http://www.frontlineservicesinc.com/assets/downloads/FAC-Zendesk%20End%20User%20License%20Agreement.pdf">Terms
                &amp; Conditions</a> -->
            <a target="_blank" href="https://frontline.group/frontline-agent-console-end-user-license-agreement/"
                >Terms &amp; Conditions</a
            >
            set forth in the EULA.</small
        >
    </div>

    <button type="submit" class="btn btn-primary block full-width m-b" [disabled]="!stepTwoForm.form.valid">
        Login
    </button>
</form>
