<div class="container-fluid border-bottom white-bg page-heading">
    <h2 class="mt-0">
        <i *ngIf="breadcrumbSrv.icon" [class]="breadcrumbSrv.icon"></i>
        {{ breadcrumbSrv.title }}
    </h2>
    <ol class="breadcrumb">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
            <li
                [ngClass]="{
                    active: i === breadcrumbs.length - 1
                }"
            >
                <a [routerLink]="[breadcrumb.Route]" *ngIf="i < breadcrumbs.length - 1">{{ breadcrumb.Title }}</a>
                <strong *ngIf="i === breadcrumbs.length - 1">{{ breadcrumb.Title }}</strong>
            </li>
        </ng-container>
    </ol>
</div>
