import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthenticationContextModel} from '.././models/authentication-context.model';
import {LocalStorageSettingsService} from './local-storage-settings.service';

@Injectable()
export class AuthenticationContextService {
    public token: string;
    public ErrorMsg: string;
    public userName: string;
    public password: string;
    public authenticationContextModel: AuthenticationContextModel;

    constructor(private _LocalStorageSettingsService: LocalStorageSettingsService) {}

    saveAuthContext(authenticationContextModel: AuthenticationContextModel) {
        this._LocalStorageSettingsService.saveSettings(authenticationContextModel);
    }

    clearAuthContext() {
        this._LocalStorageSettingsService.clearSettings();
    }

    getAuthContext(): AuthenticationContextModel {
        return this._LocalStorageSettingsService.getSettings();
    }

    tryReloadState(): AuthenticationContextModel | null {
        this.authenticationContextModel = this.getAuthContext();
        return this.authenticationContextModel;
    }
}
