import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AdminFormComponent} from '../admin-form/admin-form.component';
import {SetPasswordComponent} from '../set-password/set-password.component';
// import {ModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {OldUacUserApi} from '../../../sdk';
import {ShowMessagesService} from '../../../views/common/show-messages/show-messages.service';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-edit-admin',
    templateUrl: './edit-admin.component.html',
    styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private mssagesService: ShowMessagesService,
        private adminApi: OldUacUserApi,
        private spinner: SpinnerService,
        private modalService: BsModalService
    ) {}

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.params.subscribe((params: Params) => {
            let userId = params['id'];
            console.log(userId);
            this.AdminFormComponent.loadModel(userId).subscribe(data => {
                this.dataModel = data;
                this.setPasswordComponent.dataModel = data;
            });
        });
    }

    @ViewChild('adminForm')
    AdminFormComponent: AdminFormComponent;

    // @ViewChild('modal')
    // modal: ModalComponent;

    modalRef: BsModalRef;

    @ViewChild(SetPasswordComponent)
    setPasswordComponent: SetPasswordComponent;

    dataModel: any = {};

    saveAdmin() {
        if (this.AdminFormComponent.IsValid()) {
            this.AdminFormComponent.saveAdmin().subscribe(
                result => {
                    this.dataModel = result;
                    console.log(result);
                    this.router.navigate(['/admins/list']);
                },
                error => {
                    this.mssagesService.message.next({type: 'error', text: error});
                }
            );
        }
    }

    updateAdminStatus(status) {
        this.spinner.ShowSpinner();
        this.adminApi
            .updateAttributes(this.dataModel.id, {active: status})

            .subscribe(result => {
                this.dataModel = result;
                this.setPasswordComponent.dataModel = result;
            })
            .add(() => {
                this.spinner.HideSpinner();
            });
    }

    setPasswordModal(template: TemplateRef<any>) {
        // this.modal.open();
        this.modalRef = this.modalService.show(template);
    }

    setPassword() {
        this.setPasswordComponent.setPassword().subscribe(result => {
            // this.modal.close();
            this.modalRef.hide();
        });
    }

    ngAfterViewInit() {
        let me = this;
        setTimeout(function () {
            //me.router.createUrlTree.
        }, 500);
    }
}
