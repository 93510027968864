import { Component } from '@angular/core';


@Component({
    selector: 'minorView',
    templateUrl: 'minor-view.template.html'
})
export class minorViewComponent {
    constructor(){
        
    }

 }