import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminsComponent }    from './admins.component';
import { CreateAdminComponent }    from './create-admin/create-admin.component';
import { EditAdminComponent }    from './edit-admin/edit-admin.component';
import { DetailComponent }    from './detail/detail.component';

//import { ListComponent }    from './list/list.component';
//import { RegistrationComponent }  from './registration/registration.component';
//import { PasswordRecoveryComponent }  from './password-recovery/password-recovery.component';

const adminRoutes: Routes = [
  { path: 'admins/',  component: AdminsComponent },
  { path: 'admins/list',  component: AdminsComponent },
  { path: 'admins/add',  component: CreateAdminComponent },
  { path: 'admins/:id/edit',  component: EditAdminComponent },
  { path: 'admins/:id/detail',  component: DetailComponent }
  //{ path: 'account/registration', component: RegistrationComponent },
  //{ path: 'account/forgotpassword', component: PasswordRecoveryComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminsRoutingModule { }