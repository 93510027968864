import {Component, OnInit, NgZone} from '@angular/core';
//import { NgUploaderOptions } from 'ngx-uploader';
// import {FileUploader} from 'ng2-file-upload';
import {LoopBackConfig} from '../../sdk';
import {LoopBackAuth} from '../../sdk/core';

@Component({
    selector: 'app-data-exchange',
    templateUrl: './data-exchange.component.html'
    //styleUrls: ['./data-exchange.component.css']
})
export class DataExchangeComponent implements OnInit {
    constructor(private auth: LoopBackAuth) {}

    //private zone: NgZone;
    //private options: NgUploaderOptions;
    //private progress: number = 0;
    //private response: any = {};

    // public uploader: FileUploader = new FileUploader({url: LoopBackConfig.getPath() + '/api/containers/test/upload'});
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;

    public upload() {
        // this.uploader.authToken = this.auth.getAccessTokenId();
        // this.uploader.uploadAll();
    }
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    ngOnInit() {}
    /*
  ngOnInit() {

    this.zone = new NgZone({ enableLongStackTrace: false });
    console.log(LoopBackConfig.getPath());
    this.options = {
      url: LoopBackConfig.getPath() +'/api/containers/test/upload',
      filterExtensions: true,
      allowedExtensions: ['image/png', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      calculateSpeed: true,
      data: {
        userId: 12,
        isAdmin: true
      },
      customHeaders: {
        'custom-header': 'value'
      },
      authToken: 'asd123b123zxc08234cxcv',
      authTokenPrefix: 'Bearer'
    };
  }

  handleUpload(data: any): void {
    this.zone.run(() => {
      this.response = data;
      this.progress = Math.floor(data.progress.percent / 100);
    });
  }
  */
}
