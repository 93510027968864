import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {SpinnerService} from './spinner.service';

@NgModule({
    imports: [],
    exports: [SpinnerComponent],
    providers: [],
    declarations: [SpinnerComponent]
})
export class SpinnerModule {
    constructor() {}

    static forRoot(): ModuleWithProviders<SpinnerModule> {
        return {
            ngModule: SpinnerModule,
            providers: []
        };
    }
}
