import {Company} from '../../index';

declare var Object: any;
export interface UacUserInterface {
    title: string;
    firstName?: string;
    lastName?: string;
    type: string;
    active: boolean;
    dateCreated: Date;
    dateModified: Date;
    realm?: string;
    username?: string;
    challenges?: any;
    email: string;
    emailVerified?: boolean;
    status?: string;
    created?: Date;
    lastUpdated?: Date;
    id?: number;
    companyId?: number;
    createdById?: number;
    lastModifiedById?: number;
    password?: string;
    accessTokens?: any[];
    company?: Company;
    createdBy?: UacUser;
    lastModifiedBy?: UacUser;
}

export class UacUser implements UacUserInterface {
    'title': string;
    'firstName': string;
    'lastName': string;
    'type': string;
    'active': boolean;
    'dateCreated': Date;
    'dateModified': Date;
    'realm': string;
    'username': string;
    'challenges': any;
    'email': string;
    'emailVerified': boolean;
    'status': string;
    'created': Date;
    'lastUpdated': Date;
    'id': number;
    'companyId': number;
    'createdById': number;
    'lastModifiedById': number;
    'password': string;
    accessTokens: any[];
    company: Company;
    createdBy: UacUser;
    lastModifiedBy: UacUser;
    constructor(data?: UacUserInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `UacUser`.
     */
    public static getModelName() {
        return 'UacUser';
    }
    /**
     * @method factory
     * @author Jonathan Casarrubias
     * @license MIT
     * This method creates an instance of UacUser for dynamic purposes.
     **/
    public static factory(data: UacUserInterface): UacUser {
        return new UacUser(data);
    }
    /**
     * @method getModelDefinition
     * @author Julien Ledun
     * @license MIT
     * This method returns an object that represents some of the model
     * definitions.
     **/
    public static getModelDefinition() {
        return {
            name: 'UacUser',
            plural: 'UacUsers',
            path: 'UacUsers',
            idName: 'id',
            properties: {
                title: {
                    name: 'title',
                    type: 'string'
                },
                firstName: {
                    name: 'firstName',
                    type: 'string'
                },
                lastName: {
                    name: 'lastName',
                    type: 'string'
                },
                type: {
                    name: 'type',
                    type: 'string'
                },
                active: {
                    name: 'active',
                    type: 'boolean',
                    default: true
                },
                dateCreated: {
                    name: 'dateCreated',
                    type: 'Date',
                    default: new Date(0)
                },
                dateModified: {
                    name: 'dateModified',
                    type: 'Date',
                    default: new Date(0)
                },
                realm: {
                    name: 'realm',
                    type: 'string'
                },
                username: {
                    name: 'username',
                    type: 'string'
                },
                credentials: {
                    name: 'credentials',
                    type: 'any'
                },
                challenges: {
                    name: 'challenges',
                    type: 'any'
                },
                email: {
                    name: 'email',
                    type: 'string'
                },
                emailVerified: {
                    name: 'emailVerified',
                    type: 'boolean'
                },
                status: {
                    name: 'status',
                    type: 'string'
                },
                created: {
                    name: 'created',
                    type: 'Date'
                },
                lastUpdated: {
                    name: 'lastUpdated',
                    type: 'Date'
                },
                id: {
                    name: 'id',
                    type: 'number'
                },
                companyId: {
                    name: 'companyId',
                    type: 'number'
                },
                createdById: {
                    name: 'createdById',
                    type: 'number'
                },
                lastModifiedById: {
                    name: 'lastModifiedById',
                    type: 'number'
                },
                password: {
                    name: 'password',
                    type: 'string'
                }
            },
            relations: {
                accessTokens: {
                    name: 'accessTokens',
                    type: 'any[]',
                    model: '',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'userId'
                },
                company: {
                    name: 'company',
                    type: 'Company',
                    model: 'Company',
                    relationType: 'belongsTo',
                    keyFrom: 'companyId',
                    keyTo: 'id'
                },
                createdBy: {
                    name: 'createdBy',
                    type: 'UacUser',
                    model: 'UacUser',
                    relationType: 'belongsTo',
                    keyFrom: 'createdById',
                    keyTo: 'id'
                },
                lastModifiedBy: {
                    name: 'lastModifiedBy',
                    type: 'UacUser',
                    model: 'UacUser',
                    relationType: 'belongsTo',
                    keyFrom: 'lastModifiedById',
                    keyTo: 'id'
                }
            }
        };
    }
}
