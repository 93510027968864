import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
// import {TabsModule} from 'ng2-bootstrap/tabs';

import {AccountComponent} from './account.component';
import {LoginComponent} from './login-old/login.component';
import {AccountRoutingModule} from './account.routing';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SettingsComponent} from './settings/settings.component';
// import {Ng2Bs3ModalModule} from 'ng2-bs3-modal/ng2-bs3-modal';
// import {Ng2BootstrapModule} from 'ng2-bootstrap';
import {NavigationModule} from '../../views/common/navigation/navigation.module';
import {TopnavbarModule} from '../../views/common/topnavbar/topnavbar.module';
import {SpinnerModule} from '../../views/common/spinner/spinner.module';
import {MainViewModule} from './main-view/main-view.module';
import {ShowMessagesModule} from '../../views/common/show-messages/show-messages.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        AccountRoutingModule,
        // Ng2Bs3ModalModule,
        FormsModule,

        NavigationModule,
        TopnavbarModule,
        // SpinnerModule,

        SharedModule,

        MainViewModule
    ],
    exports: [MainViewModule],
    declarations: [
        AccountComponent,
        LoginComponent,
        // RegistrationComponent,
        PasswordRecoveryComponent,
        ChangePasswordComponent,
        SettingsComponent
    ]
})
export class AccountModule {}
