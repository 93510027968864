import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {OldUacUserApi} from '../../../sdk';
import {SpinnerService} from '../../../views/common/spinner/spinner.service';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
    public dataModel: any = {};
    public newPassword: string = '';
    public confirmPassword: string = '';
    constructor(
        private adminApi: OldUacUserApi,
        private spinner: SpinnerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {}

    setPassword(): BehaviorSubject<any> {
        let bhv = new BehaviorSubject(null);
        if (this.newPassword != this.confirmPassword) {
            return null;
        }
        this.adminApi.updateAttributes(this.dataModel.id, {password: this.newPassword}).subscribe(result => {
            console.log(result);
            bhv.next(result);
        });
        return bhv;
    }
}
