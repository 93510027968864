export class AuthenticationContextModel {
    public access_token: string;
    public token_type: string;
    public id_token: string;
    public expires_in: number;
    public refresh_token: string;
    public scope: string;
    public resource_server_base_uri: string;
    public refresh_token_server_uri: string;
    public agent_id: string;
    public team_id: string;

    constructor(data?: Partial<AuthenticationContextModel>) {
        Object.assign(this, data);
    }
}
