<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center m-t-lg">
                <h1>Frontline Agent Console</h1>
                <small>
                    <p>The logged in and was active in this period.</p>
                </small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <ul class="nav nav-pills">
                <li>
                    <h2 style="margin-top: 10px">Agent Activity For Given Period</h2>
                </li>
                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
                        {{currentMonth}}
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a *ngFor="let m of months" (click)="currentMonth = m" href="javascript:void(0)">{{m}}</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
                        {{currentYear}}
                        <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a *ngFor="let y of years" (click)="currentYearData(y)" href="javascript:void(0)">{{y}}</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <button class="btn btn-primary" (click)="loadData()" href="javascript:void(0)">
                        <!-- <i class="glyphicon glyphicon-repeat"></i> -->
                        Apply
                    </button>
                </li>
                <li class="dropdown">
                    <button class="btn btn-primary" (click)="loadThisMonth()">
                        <!-- <i class="glyphicon glyphicon-chevron-left"></i> -->
                        Current Billing Period
                    </button>
                </li>
                <li class="dropdown">
                    <button class="btn btn-primary" (click)="loadPreviousMonth()">
                        <!-- <i class="glyphicon glyphicon-chevron-left"></i> -->
                        Previous Billing Period
                    </button>
                </li>
            </ul>
            <h3>Total Active Agents: <span *ngIf="!showDetail">Loading...</span><span *ngIf="showDetail">{{totalActiveAgents}}</span></h3>
        </div>
        <!-- <div class="col-lg-4">
            <h3>Active vs In-Active</h3>
        </div> -->
    </div>
    <div class="row">
        <div class="col-lg-12">
            <!-- <chart *ngIf="showChart" [type]="type" [data]="data" [options]="options" style="height: 400px"></chart> -->
            <app-bar-chart class="d-block" [data]="data" [options]="options" #agentActivityChart style="height: 400px"></app-bar-chart>
            <div *ngIf="!showChart" style="height: 400px">Loading...</div>
        </div>
        <!-- <div class="col-lg-4">
            <app-bar-chart [type]="type1" [data]="data1" [options]="options" #agentActivityChart1 style="height: 400px"></app-bar-chart>
            <div *ngIf="!showPieChart" style="height: 400px">Loading...</div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="project-list" *ngIf="!showDetail">Loading...</div>
            <div class="project-list" *ngIf="showDetail">
                <!-- <table class="table table-hover" [mfData]="rows" #mf="mfDataTable" [mfRowsOnPage]="5">-->
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="project-title" style="text-align: left">Agent</th>
                            <th class="project-title" style="text-align: right">Number Of Logins</th>
                            <th class="project-title" style="text-align: right">Date Of Last Login</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of rows">
                            <td class="project-status" style="text-align: left">{{item.firstName}} {{item.lastName}} ({{item.email}})</td>
                            <td class="project-title" style="text-align: right">
                                <small>{{item.totalLogins}}</small>
                            </td>
                            <td class="project-completion" style="text-align: right">
                                <small>{{item.lastLoginDate |date:'MM/dd/yyyy @ h:mma'}}</small>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="12"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
