import { Subscription } from "rxjs";

export class BaseComponent {
    protected subscriptions$: Array<Subscription> = [];

    set addSubscription$(val: Subscription) {
        this.subscriptions$.push(val);
    }

    constructor() {
        //
    }

    destroySubscriptions() {
        if (this.subscriptions$ && this.subscriptions$.length > 0) {
            for (const sub of this.subscriptions$) {
                sub.unsubscribe();
            }
            this.subscriptions$.splice(0, this.subscriptions$.length);
        }
    }
}
