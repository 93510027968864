import {HttpClientModule} from '@angular/common/http';
// import {HttpModule} from '@angular/http';
import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {FacInternalStorage} from './storage/storage.swaps';
import {FacCookieBrowser} from './storage/cookie.browser';
import {FacStorageBrowser} from './storage/storage.browser';
import {AuthenticationService} from 'src/app/modules/authentication/services/authentication.service';

import {FrontlineSDKModels} from './services/custom/frontline-sdk-models';
import {ApplicationAuthService, JSONSearchParams} from './core';
import {
    AuthencationApi,
    CrmTemplatesApi,
    EmailNotificationApi,
    EmailNotificationHistoryApi,
    EmailTemplatesApi,
    NewAgentsApi,
    NewCompaniesApi,
    NewCrmIntegrationApplicationsApi,
    NewReportsApi,
    StorageApi
} from './api-services';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
        // HttpModule
    ],
    declarations: [],
    exports: [],
    providers: []
})
export class FrontlineApiBrowserModule {
    static forRoot(
        internalStorageProvider: any = {
            provide: FacInternalStorage,
            useClass: FacCookieBrowser
        }
    ): ModuleWithProviders<FrontlineApiBrowserModule> {
        return {
            ngModule: FrontlineApiBrowserModule,
            providers: [
                AuthencationApi,
                NewCompaniesApi,
                NewAgentsApi,
                NewCrmIntegrationApplicationsApi,

                EmailNotificationApi,
                EmailNotificationHistoryApi,

                NewReportsApi,
                EmailTemplatesApi,

                CrmTemplatesApi,

                StorageApi,

                internalStorageProvider,
                FrontlineSDKModels,
                ApplicationAuthService,
                JSONSearchParams,
                AuthenticationService,
                {provide: FacInternalStorage, useClass: FacStorageBrowser}
            ]
        };
    }
}

export * from './models/index';
export * from './view-models/index';
export * from './services/index';
// export * from './lb.config';
export * from './storage/storage.swaps';
export {FacCookieBrowser as CookieBrowser} from './storage/cookie.browser';
export {FacStorageBrowser as StorageBrowser} from './storage/storage.browser';
