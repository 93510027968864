import {AfterViewInit, Component, HostBinding, OnInit} from '@angular/core';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit, AfterViewInit {
    @HostBinding('class') class = 'authentication-component gray-bg';
    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {}

    get year() {
        return new Date().getFullYear();
    }
}
