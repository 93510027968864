import {Component, OnInit, AfterViewInit, OnDestroy, Input} from '@angular/core';
import {BaseComponent} from '../../classes/base.component';
import {BreadcrumbModel} from '../../models';
import {BreadcrumbService} from '../../services/breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() breadcrumbs: Array<BreadcrumbModel> = [];

    constructor(public breadcrumbSrv: BreadcrumbService) {
        super();
    }

    ngOnInit() {
        this.addSubscription$ = this.breadcrumbSrv.castBreadcrumb.subscribe(res => {
            this.breadcrumbs = res;
        });
    }

    ngAfterViewInit() {
        //
    }

    ngOnDestroy() {
        this.destroySubscriptions();
    }
}
