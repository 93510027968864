import {Injectable} from '@angular/core';
import {ToastrService, ActiveToast} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {elementAt, take} from 'rxjs/operators';

@Injectable()
export class AppToasterService {
    constructor(public toastrSrv: ToastrService) {}

    show(
        type: 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING',
        title: string,
        messageBody: string,
        keepOpen: boolean = false,
        timeout: number = 5000,
    ) {
        let tostr: ActiveToast<any>;
        return new Observable(subscriber => {
            this.toastrSrv.toastrConfig.autoDismiss = !keepOpen;
            this.toastrSrv.toastrConfig.timeOut = timeout;
            type.toLocaleLowerCase();

            tostr = this.toastrSrv[type.toLocaleLowerCase()](messageBody, title, {
                progressBar: true,
                disableTimeOut: 'extendedTimeOut',
            });

            // if (type === 'SUCCESS') {
            //     tostr = this.toastrSrv.success(messageBody, title);
            // } else if (type === 'ERROR') {
            //     tostr = this.toastrSrv.error(messageBody, title);
            // } else if (type === 'INFO') {
            //     tostr = this.toastrSrv.info(messageBody, title);
            // } else if (type === 'WARNING') {
            //     tostr = this.toastrSrv.warning(messageBody, title);
            // }

            tostr.onShown.pipe(take(1)).subscribe(x => subscriber.next('onShown'));
            tostr.onAction.pipe(take(1)).subscribe(x => subscriber.next('onAction'));
            tostr.onHidden.pipe(take(1)).subscribe(x => subscriber.next('onHidden'));
            tostr.onTap.pipe(take(1)).subscribe(x => subscriber.next('onTap'));
        });
    }
}
