import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Router} from '@angular/router';
import {OldUacUserApi} from '../../../sdk';
import {NavigationComponent} from './navigation.component';

@NgModule({
    declarations: [NavigationComponent],
    imports: [CommonModule, RouterModule],
    exports: [NavigationComponent]
})
export class NavigationModule {
    constructor(private userService: OldUacUserApi, private router: Router) {}
}
