<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center m-t-lg">
                <h1>
                    Simple example of second view
                </h1>
                <small>Written as an minor-view.</small>
            </div>
        </div>
    </div>
</div>