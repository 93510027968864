import {Injectable, Inject, Optional} from '@angular/core';
import {SDKModels} from './SDKModels';
import {BaseLoopBackApi} from '../../../core/base.api';
import {LoopBackConfig} from '../../../lb.config';
import {LoopBackAuth} from '../../../core/auth.service';
import {LoopBackFilter} from '../../models/BaseModels';
import {JSONSearchParams} from '../../../core/search.params';
import {ErrorHandler} from '../../../core/error.service';
import {Subject, Observable} from 'rxjs';
import {Container} from '../../models/Container';
import {SocketConnection} from '../../../sockets/socket.connections';
import {HttpClient} from '@angular/common/http';

/**
 * Api services for the `Container` model.
 */
@Injectable()
export class ContainerApi extends BaseLoopBackApi {
    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SocketConnection) protected connection: SocketConnection,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Inject(JSONSearchParams) protected searchParams: JSONSearchParams,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, connection, models, auth, searchParams, errorHandler);
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Container` object.)
     * </em>
     */
    public getContainers(customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers';
        let _routeParams: any = {};
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {object} data Request data.
     *
     * This method expects a subset of model properties as request parameters.
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Container` object.)
     * </em>
     */
    public createContainer(options: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers';
        let _routeParams: any = {};
        let _postBody: any = {
            options: options
        };
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `` – `{}` -
     */
    public destroyContainer(container: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container';
        let _routeParams: any = {
            container: container
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Container` object.)
     * </em>
     */
    public getContainer(container: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container';
        let _routeParams: any = {
            container: container
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @returns {object[]} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Container` object.)
     * </em>
     */
    public getFiles(container: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container/files';
        let _routeParams: any = {
            container: container
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @param {string} file
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * <em>
     * (The remote method definition does not provide any description.
     * This usually means the response is a `Container` object.)
     * </em>
     */
    public getFile(container: any, file: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container/files/:file';
        let _routeParams: any = {
            container: container,
            file: file
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @param {string} file
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `` – `{}` -
     */
    public removeFile(container: any, file: any, customHeaders?: Function): Observable<any> {
        let _method: string = 'DELETE';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container/files/:file';
        let _routeParams: any = {
            container: container,
            file: file
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @param {object} data Request data.
     *
     *  - `req` – `{object}` -
     *
     *  - `res` – `{object}` -
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * Data properties:
     *
     *  - `result` – `{object}` -
     */
    public upload(container: any, req: any = {}, res: any = {}, customHeaders?: Function): Observable<any> {
        let _method: string = 'POST';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container/upload';
        let _routeParams: any = {
            container: container
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * <em>
     * (The remote method definition does not provide any description.)
     * </em>
     *
     * @param {string} container
     *
     * @param {string} file
     *
     * @param {object} req
     *
     * @param {object} res
     *
     * @returns {object} An empty reference that will be
     *   populated with the actual data once the response is returned
     *   from the server.
     *
     * This method returns no data.
     */
    public download(
        container: any,
        file: any,
        req: any = {},
        res: any = {},
        customHeaders?: Function
    ): Observable<any> {
        let _method: string = 'GET';
        let _url: string =
            LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() + '/containers/:container/download/:file';
        let _routeParams: any = {
            container: container,
            file: file
        };
        let _postBody: any = {};
        let _urlParams: any = {};
        let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
        return result;
    }

    /**
     * The name of the model represented by this $resource,
     * i.e. `Container`.
     */
    public getModelName() {
        return 'Container';
    }
}
