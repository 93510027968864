import {Location} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/modules/authentication/services/authentication.service';
import {AuthenticationContextService} from 'src/app/services/inContact/authentication-context.service';
import {NewCompanyModel} from 'src/sdk';
import {AuthencationApi, NewCompaniesApi} from 'src/sdk/api-services';
import {ApplicationAuthService} from 'src/sdk/core';
import {smoothlyMenu} from '../../../../app/app.helpers';
import {OldUacUserApi} from '../../../sdk';
import {SpinnerService} from '../spinner/spinner.service';
declare var jQuery: any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html'
})
export class TopnavbarComponent implements OnInit {
    public companiesList: Array<NewCompanyModel> = [];

    public keyword: string = 'title';

    public currentCompanyId: number | null = null;

    constructor(
        private userService: OldUacUserApi,
        private router: Router,
        private location: Location,
        public authenticationApi: AuthencationApi,
        private authenticationContextSrv: AuthenticationContextService,
        @Inject(SpinnerService) public spinnerSrv: SpinnerService,
        @Inject(ApplicationAuthService) public applicationAuth: ApplicationAuthService,
        @Inject(AuthenticationService) public authenticationSrv: AuthenticationService,
        @Inject(NewCompaniesApi) public newCompaniesApi: NewCompaniesApi
    ) {}

    public ngOnInit(): void {
        this.loadCompanies();
    }

    public loadCompanies() {
        this.spinnerSrv.ShowSpinner();
        this.newCompaniesApi
            .companies({
                order: 'title'
            })
            .subscribe(res => {
                this.companiesList = res;
            })
            .add(() => {
                this.currentCompanyId = this.applicationAuth.getCurrentCompanyId();
                this.spinnerSrv.HideSpinner();
            });
    }

    public companyClick(company: any) {
        this.applicationAuth.setCompany(company);
        // window.location.reload();
        const urlParts = this.location.path().split('/');
        if (urlParts.length > 2) {
            window.location.href = urlParts.slice(0, 3).join('/');
        } else {
            window.location.href = urlParts.join('/');
        }
    }

    toggleNavigation(): void {
        jQuery('body').toggleClass('mini-navbar');
        smoothlyMenu();
    }

    logoutOpenId() {
        const result = window.open(
            `${this.authenticationSrv.ssoBaseUri}/auth/authorize/logout`,
            '_blank',
            'toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400'
        );

        this.authenticationContextSrv.clearAuthContext();
        this.applicationAuth.clear();

        this.spinnerSrv.HideSpinner();
        this.router.navigate(['/authentication/login-step-one']);

        setTimeout(() => {
            result?.close();
        }, 2000);
    }

    logout() {
        let me = this;
        this.spinnerSrv.ShowSpinner();
        // this.userService.logout().subscribe(result => {
        //     this.spinner.HideSpinner();
        //     me.router.navigate(['/authentication/login-tep-one']);
        //     this.spinner.HideSpinner();
        // });

        if (this.authenticationSrv.isSSOLogin) {
            this.logoutOpenId();
            this.spinnerSrv.HideSpinner();
        } else {
            this.authenticationApi
                .Logout()
                .subscribe(res => {})
                .add(() => {
                    this.authenticationContextSrv.clearAuthContext();
                    this.applicationAuth.clear();

                    this.spinnerSrv.HideSpinner();
                    me.router.navigate(['/authentication/login-step-one']);
                });
        }
    }
}
